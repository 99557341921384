import React, { useEffect, useRef, useState } from "react";
import { Collapse, Form, ListGroup, Navbar } from "react-bootstrap";
import { HiMiniChevronDown, HiMiniChevronUp } from "react-icons/hi2";
import { IoShieldHalfOutline } from "react-icons/io5";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { GoUnlock } from "react-icons/go";
import { ChangePassword, TwoFA } from "./Popups.js";
import UseToggle from "../Hooks/UseToggle";
import { authServices } from "../APIServices/authServices.jsx";
import { FaRegUser } from "react-icons/fa6";

const Header = ({ handleAddNewTab, userCurrentData }) => {
  const toggleRef = useRef(null);
  const currentUrl = window.location.href;
  const splitUrl = currentUrl?.split("/");
  const currentPath = splitUrl[3];

  const [profileImage, setProfileImage] = useState("");
  const [emailTwoFA, setEmailTwoFA] = useState(false);
  const [status, setStatus] = useState(false);
  const [appTwoFA, setAppTwoFA] = useState(false);
  const [authType, setAuthType] = useState("");
  const [open, setOpen] = useState(false);
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const logout = async () => {
    await authServices.logout();
  };

  const getTwoFactorAuthType = async () => {
    let data = await authServices.getTwoFactorAuthType();
    setAuthType(data?.authType);
    setStatus(data?.status);
    setAppTwoFA(data?.appTwoFA);
    setEmailTwoFA(data?.emailTwoFA);
  };

  const openTwoFactorAuth = () => {
    getTwoFactorAuthType();
    setToggle();
  };

  let previousRole = JSON.parse(localStorage.getItem("previousRole"));

  const logoutStudy = () => {
    window.location.href = `/client`;
    localStorage.setItem("currentRole", JSON.stringify(previousRole));
    localStorage.removeItem("previousRole");
    localStorage.removeItem("tabs");
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (toggleRef?.current && !toggleRef?.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    getTwoFactorAuthType();
    setProfileImage(
      userCurrentData?.profileImage && userCurrentData?.profileImage
    );
  }, [userCurrentData]);

  return (
    <>
      <Navbar expand="lg" className="bg-color px-2">
        <div ref={toggleRef} className="hstack justify-content-between w-100">
          <div className="text-white fw-bold">Galax® CDISC™</div>
          <div
            onClick={() => setOpen(!open)}
            className={`profileDropdown fs-13 ${open && "profileActive"}`}
          >
            <div className="hstack gap-1">
              <img
                src={profileImage}
                alt="Profile"
                className="rounded-circle border"
                title="User Profile"
                style={{
                  width: 20,
                  height: 20,
                  objectFit: "cover",
                }}
              />
              <span className="fw-bold">{userCurrentData?.fullName}</span>
              {open ? <HiMiniChevronUp /> : <HiMiniChevronDown />}
            </div>
            <div open={open}>
              <div
                className="position-absolute"
                style={{ right: 6, top: 40, zIndex: 999 }}
              >
                <div className="w-100 fs-13">
                  <Collapse in={open}>
                    <div>
                      <ListGroup
                        style={{
                          width: 200,
                          maxHeight: 250,
                          borderRadius: "0px 0px 3px 3px",
                        }}
                        className="overflow-auto position-relative shadow"
                      >
                        <ListGroup.Item
                          action
                          className="p-2 ps-3"
                          onClick={() =>
                            handleAddNewTab(
                              "Profile",
                              "fa-solid fa-user",
                              "userId"
                            )
                          }
                        >
                          <div className="hstack justify-content-between">
                            <FaRegUser className="me-1" />
                            <Form.Label
                              className="cursor-pointer text-truncate w-100"
                              style={{ margin: "2px 0px 0px" }}
                            >
                              Profile
                            </Form.Label>
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          className="p-2 ps-3"
                          onClick={() => openTwoFactorAuth()}
                        >
                          <div className="hstack justify-content-between">
                            <IoShieldHalfOutline className="me-1" />
                            <Form.Label
                              className="cursor-pointer text-truncate w-100"
                              style={{ margin: "2px 0px 0px" }}
                            >
                              2FA
                            </Form.Label>
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                          action
                          className="p-2 ps-3"
                          onClick={setToggle1}
                        >
                          <div className="hstack justify-content-between">
                            <GoUnlock className="me-1" />
                            <Form.Label
                              className="cursor-pointer text-truncate w-100"
                              style={{ margin: "2px 0px 0px" }}
                            >
                              Change Possword
                            </Form.Label>
                          </div>
                        </ListGroup.Item>
                        {currentPath === "study" &&
                          <ListGroup.Item
                            action
                            className="p-2 ps-3"
                            onClick={logoutStudy}
                          >
                            <div className="hstack justify-content-between">
                              <RiLogoutCircleRLine className="me-1" />
                              <Form.Label
                                className="cursor-pointer text-truncate w-100"
                                style={{ margin: "2px 0px 0px" }}
                              >
                                Logout Form Study
                              </Form.Label>
                            </div>
                          </ListGroup.Item>
                        }
                        <ListGroup.Item
                          action
                          className="p-2 ps-3"
                          onClick={logout}
                        >
                          <div className="hstack justify-content-between">
                            <RiLogoutCircleRLine className="me-1" />
                            <Form.Label
                              className="cursor-pointer text-truncate w-100"
                              style={{ margin: "2px 0px 0px" }}
                            >
                              Logout Software
                            </Form.Label>
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Navbar>
      <TwoFA
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Two Factor Authentication"}
        getTwoFactorAuthType={getTwoFactorAuthType}
        setAuthType={setAuthType}
        authType={authType}
        status={status}
        appTwoFA={appTwoFA}
        emailTwoFA={emailTwoFA}
      />
      <ChangePassword
        Show={!toggle1}
        Hide={setToggle1}
        Size={"md"}
        Title={`Change Password`}
        Close={"Close"}
        Submit={`Save`}
      />
    </>
  );
};

export default Header;

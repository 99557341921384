import React, { useState } from "react";
import { Collapse, Form, InputGroup, Offcanvas } from "react-bootstrap";
import { FaCheck, FaXmark } from "react-icons/fa6";

const LegacyPopup = ({ Show, Hide, Title }) => {
  // Date Range
  const [openDay, setOpenDay] = useState(false);
  const [openMonth, setOpenMonth] = useState(false);
  const [openYear, setOpenYear] = useState(false);
  // Time Range
  const [openHour, setOpenHour] = useState(false);
  const [openMinut, setOpenMinut] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);

  const clearFilterData = () => {
    Hide();
  };

  return (
    <>
      <Offcanvas
        show={Show}
        onHide={Hide}
        placement="end"
        style={{ width: 600 }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title>{Title} </Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="pt-2 px-3 overflow-auto"
            style={{ height: "calc(100vh - 84px)" }}
          >
            <div className="hstack gap-2">
              <Form.Group className="mb-2 position-relative">
                <Form.Label className="fw-bold mb-1">Day</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="dd"
                  onClick={() => setOpenDay(!openDay)}
                  maxLength="3"
                  className="rounded-1"
                  style={{ width: 135 }}
                  size="3"
                />
                <Collapse
                  in={openDay}
                  className="position-absolute w-100 shadow z-1 bg-white fs-13 selectValues"
                  style={{ top: 57 }}
                >
                  <div className="hstack flex-wrap">
                    <div className={`border-bottom px-3 CP py-1 active`}>1</div>
                    <div className={`px-3 CP py-1 fs-13 `}>{"UNK"}</div>
                  </div>
                </Collapse>
              </Form.Group>
              <Form.Group className="mb-2 position-relative">
                <Form.Label className="fw-bold mb-1">Month</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="mm"
                  onClick={() => setOpenMonth(!openMonth)}
                  maxLength="3"
                  className="rounded-1"
                  style={{ width: 135 }}
                  size="3"
                />
                <Collapse
                  in={openMonth}
                  className="position-absolute w-100 shadow z-1 bg-white fs-13 selectValues"
                  style={{ top: 57 }}
                >
                  <div className="hstack flex-wrap">
                    <div className={`border-bottom px-3 CP py-1 active`}>
                      Jan
                    </div>
                    <div className={`px-3 CP py-1 fs-13 `}>{"UNK"}</div>
                  </div>
                </Collapse>
              </Form.Group>
              <Form.Group className="mb-2 position-relative">
                <Form.Label className="fw-bold mb-1">Year</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="yyyy"
                  onClick={() => setOpenYear(!openYear)}
                  maxLength="4"
                  className="rounded-1"
                  style={{ width: 276 }}
                  size="3"
                />
                <Collapse
                  in={openYear}
                  className="position-absolute w-100 shadow z-1 bg-white fs-13 selectValues"
                  style={{ top: 57 }}
                >
                  <div className="hstack flex-wrap">
                    <div className={`border-bottom px-3 CP py-1 active`}>
                      2024
                    </div>
                    <div className={`px-3 CP py-1 fs-13 `}>{"UNK"}</div>
                  </div>
                </Collapse>
              </Form.Group>
            </div>
            <div className="hstack gap-2">
              <Form.Group className="mb-2 position-relative">
                <Form.Label className="fw-bold mb-1">Hour</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="HH"
                  onClick={() => setOpenHour(!openHour)}
                  maxLength="3"
                  className="rounded-1"
                  style={{ width: 135 }}
                  size="3"
                />
                <Collapse
                  in={openHour}
                  className="position-absolute w-100 shadow z-1 bg-white fs-13 selectValues"
                  style={{ top: 57 }}
                >
                  <div className="hstack flex-wrap">
                    <div className={`border-bottom px-3 CP py-1 active`}>1</div>
                    <div className={`px-3 CP py-1 fs-13 `}>{"UNK"}</div>
                  </div>
                </Collapse>
              </Form.Group>
              <Form.Group className="mb-2 position-relative">
                <Form.Label className="fw-bold mb-1">Minut</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="MM"
                  onClick={() => setOpenMinut(!openMinut)}
                  maxLength="3"
                  className="rounded-1"
                  style={{ width: 135 }}
                  size="3"
                />
                <Collapse
                  in={openMinut}
                  className="position-absolute w-100 shadow z-1 bg-white fs-13 selectValues"
                  style={{ top: 57 }}
                >
                  <div className="hstack flex-wrap">
                    <div className={`border-bottom px-3 CP py-1 active`}>1</div>
                    <div className={`px-3 CP py-1 fs-13 `}>{"UNK"}</div>
                  </div>
                </Collapse>
              </Form.Group>
              <Form.Group className="mb-2 position-relative">
                <Form.Label className="fw-bold mb-1">Second</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="SS"
                  onClick={() => setOpenSecond(!openSecond)}
                  maxLength="3"
                  className="rounded-1"
                  style={{ width: 135 }}
                  size="3"
                />
                <Collapse
                  in={openSecond}
                  className="position-absolute w-100 shadow z-1 bg-white fs-13 selectValues"
                  style={{ top: 57 }}
                >
                  <div className="hstack flex-wrap">
                    <div className={`border-bottom px-3 CP py-1 active`}>1</div>
                    <div className={`px-3 CP py-1 fs-13 `}>{"UNK"}</div>
                  </div>
                </Collapse>
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label
                  className="fw-bold mb-1 text-white"
                  style={{ pointerEvents: "none" }}
                >
                  Time Zone
                </Form.Label>
                <InputGroup style={{ borderRadius: 3 }}>
                  <InputGroup.Text
                    className="active"
                    style={{ fontSize: 11, borderRadius: "3px 0 0 3px" }}
                  >
                    AM
                  </InputGroup.Text>
                  <InputGroup.Text
                    style={{ fontSize: 11, borderRadius: "0 3px 3px 0" }}
                  >
                    PM
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </div>
          </div>
          <div className="text-end py-2 px-3 shadow-sm bg-light w-100">
            <button
              className="py-1 px-3 border rounded-1 text-white bg-danger me-2 fs-13"
              onClick={() => clearFilterData()}
            >
              <FaXmark /> Clear
            </button>
            <button className="py-1 px-3 border rounded-1 text-white bg-color fs-13">
              <FaCheck /> Apply
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default LegacyPopup;

import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { addressServices } from "../../../../../APIServices/addressServices";
import { userServices } from "../../../../../APIServices/UserServices/usersServices";

const UpdateSubadminModal = ({
  designationList,
  permissionList,
  getUserData,
  subadminData,
  setId,
}) => {
  let [firstName, setFirstName] = useState();
  let [middleName, setMiddleName] = useState("");
  let [lastName, setLastName] = useState("");
  let [email, setEmail] = useState("");
  let [phoneNumber, setPhoneNumber] = useState("");
  let [gender, setGender] = useState("");
  let [address, setAddress] = useState("");
  let [zipCode, setZipCode] = useState("");
  let [country, setCountry] = useState("");
  let [state, setState] = useState("");
  let [city, setCity] = useState("");
  let [permissionId, setPermissionId] = useState("");
  let [designationId, setDesignationId] = useState("");
  let [phoneError, setPhoneError] = useState("");

  const handleUpdate = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update Data this User?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = subadminData._id;
        userData.firstName = firstName;
        userData.middleName = middleName;
        userData.lastName = lastName;
        userData.phone = phoneNumber;
        userData.gender = gender;
        let data = await userServices.updateUserData(userData);
        if (data?.statusCode === 200) {
          getUserData();
        }
      }
    });
  };

  const findCountryName = async () => {
    let data = await addressServices.findCountryName(subadminData?.countryId);
    setCountry(data?.data);
  };

  const findStateName = async () => {
    let data = await addressServices.findStateName(subadminData?.stateId);
    setState(data?.data);
  };

  const findCityName = async () => {
    let data = await addressServices.findCityName(subadminData?.cityId);
    setCity(data?.data);
  };

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;
    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number Should have 10 digit");
    }
  };

  useEffect(() => {
    findCountryName();
    findStateName();
    findCityName();
    setFirstName(subadminData?.firstName);
    setMiddleName(subadminData?.middleName);
    setLastName(subadminData?.lastName);
    setEmail(subadminData?.email);
    setPhoneNumber(subadminData?.phone);
    setGender(subadminData?.gender);
    setPermissionId(subadminData?.permissionId);
    setDesignationId(subadminData?.designationId);
    setAddress(subadminData?.address);
    setZipCode(subadminData?.zipCode);
  }, [subadminData]);

  const clearForm = () => {
    setId("");
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setGender("");
    setPermissionId("");
    setDesignationId("");
    setAddress("");
    setZipCode("");
  };

  return (
    <>
      <Form.Group className="mb-2">
        <div className="hstack justify-content-between">
          <Form.Label className="mb-1 fw-bold">
            First Name <span className="text-danger">*</span>
          </Form.Label>
          <button
            className="text-white border-primary bg-primary border-0 fs-10 br3"
            onClick={clearForm}
          >
            Add New
          </button>
        </div>
        <Form.Control
          required
          type="text"
          onChange={(e) => setFirstName(e.target.value)}
          value={firstName}
          placeholder="Enter First Name"
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Middle Name</Form.Label>
        <Form.Control
          required
          type="text"
          onChange={(e) => setMiddleName(e.target.value)}
          value={middleName}
          placeholder="Enter Middle Name"
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Last Name <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
          placeholder="Enter Last Name"
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Email <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          disabled
          type="email"
          placeholder="Enter Email ID"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Phone Number <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          onChange={(e) => {
            setPhoneNumber(e.target.value);
            validatePhoneNumber(e.target.value);
          }}
          value={phoneNumber}
          type="number"
          placeholder="Enter Phone Number"
        />
        {phoneError && (
          <span className="message" style={{ color: "red", fontSize: 10 }}>
            {phoneError}
          </span>
        )}
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Gender <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          onChange={(e) => setGender(e.target.value)}
          aria-label="Default select example"
        >
          <option>Select Gender</option>
          <option selected={gender === "male"} value="male">
            Male
          </option>
          <option selected={gender === "female"} value="female">
            Female
          </option>
          <option selected={gender === "other"} value="other">
            Other
          </option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Designation <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          aria-label="Default select example"
          disabled
          onChange={(e) => setDesignationId(e.target.value)}
        >
          <option>Select Designation</option>
          {designationList?.map((item, index) => (
            <option
              selected={designationId === item._id}
              key={index}
              value={item._id}
            >
              {item.designation}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Permission <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          aria-label="Default select example"
          disabled
          onChange={(e) => setPermissionId(e.target.value)}
        >
          <option>Select Permission</option>
          {permissionList?.map((item, index) => (
            <option
              selected={permissionId === item._id}
              key={index}
              value={item._id}
            >
              {item.permission}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group as={Col} md="12" className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Address <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          disabled
          onChange={(e) => setAddress(e.target.value)}
          value={address}
          as="textarea"
          placeholder="Enter Address"
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Country <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control required disabled type="text" value={country} />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          State <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control required disabled type="text" value={state} />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          City <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control required disabled type="text" value={city} />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Zip Code <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          onChange={(e) => setZipCode(e.target.value)}
          value={zipCode}
          required
          disabled
          type="number"
          placeholder="Enter Zip Code"
        />
      </Form.Group>
      <div className="text-end">
        <button
          className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
          disabled={
            !firstName || !gender || !lastName || !phoneNumber || phoneError
          }
          onClick={handleUpdate}
        >
          Update User
        </button>
      </div>
    </>
  );
};

export default UpdateSubadminModal;

import React, { useEffect, useState } from "react";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import AddNewPermissionModal from "./AddNewPermissionModal";
import PermissionTableData from "./PermissionTableData";
import AccessCheckboxList from "./AccessCheckboxList";
import { designationPermissionServices } from "../../../../../APIServices/UserServices/designationPermissionServices";
import UseToggle from "../../../../../Hooks/UseToggle";
import { CiFilter } from "react-icons/ci";
import { VscClose } from "react-icons/vsc";
import { BiCollapse, BiExpand } from "react-icons/bi";
import SplitPane from "react-split-pane";
import { AiOutlinePlus } from "react-icons/ai";

const Permission = ({ onMouseDown, tableRef }) => {
  const { toggle, setToggle } = UseToggle();
  const [loader, setLoader] = useState(false);
  const [viewAccessId, setViewAccessId] = useState("");
  const [viewAccessList, setViewAccessList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [permissionName, setPermissionName] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const getPermissionsList = async () => {
    setLoader(true);
    let data = await designationPermissionServices.getPermissionsList();
    setPermissionList(data?.data);
    setLoader(false);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    let data = await designationPermissionServices.searchPermission(userData);
    setPermissionList(data?.data);
  };

  const getAssignedPermission = async (viewAccessId) => {
    let data = await designationPermissionServices.getAssignedPermission(
      viewAccessId
    );
    setViewAccessList(data?.data);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const HeaderTools = [
    {
      title: "Add Management",
      icon: <AiOutlinePlus />,
      onClick: () => setToggle(),
    },
  ];

  useEffect(() => {
    getPermissionsList();
  }, []);
  return (
    <>
      <div
        className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}
      >
        <div
          className="hstack justify-content-between w-100 border-bottom"
          style={{ padding: "4.6px 9px" }}
        >
          <div className="hstack gap-2">
            {HeaderTools?.map((button, index) => (
              <React.Fragment key={index}>
                <button
                  title={button.title}
                  className="tabButtons border rounded-1 fs-13"
                  style={{ padding: "1px 5px" }}
                  onClick={button.onClick}
                >
                  {button.icon}
                </button>
              </React.Fragment>
            ))}
            <div className="vr"></div>
            <InputGroup>
              <InputGroup.Text className="p-0 border-0">
                <button
                  className="tabButtons border fs-13"
                  style={{ padding: "1px 5px", borderRadius: "3px 0 0 3px" }}
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  <CiFilter />
                </button>
              </InputGroup.Text>
              <div
                className={`p-0 d-flex gap-0 ${
                  isExpanded ? "expand-input" : "collapse-input"
                }`}
                style={{
                  maxWidth: isExpanded ? "200px" : "0px",
                  transition: "max-width 0.3s ease-out",
                  overflow: "hidden",
                }}
              >
                <Form.Control
                  className="p-0 rounded-0"
                  type="search"
                  onChange={() => handleSearch()}
                />
                <InputGroup.Text className="p-0 border-0">
                  <button
                    className="tabButtons border fs-13"
                    style={{ padding: "1px 5px", borderRadius: "0 3px 3px 0" }}
                  >
                    <VscClose />
                  </button>
                </InputGroup.Text>
              </div>
            </InputGroup>
          </div>
          <button
            className="tabButtons border rounded-1 fs-13"
            title={!isFullScreen ? "Full Mode" : "Original Mode"}
            onClick={toggleFullScreen}
          >
            {isFullScreen ? <BiCollapse /> : <BiExpand />}
          </button>
        </div>
        <div className="position-relative">
          <SplitPane
            split="horizontal"
            style={{
              height: isFullScreen
                ? "calc(100vh - 35px)"
                : "calc(100vh - 148px)",
            }}
            defaultSize={"100%"}
          >
            <SplitPane
              split="vertical"
              minSize={20}
              defaultSize={permissionList?.length > 0 ? "60%" : "100%"}
            >
              {loader ? (
                <div className="hstack justify-content-center h-100">
                  <Spinner />
                </div>
              ) : permissionList?.length === 0 ? (
                <div className="hstack justify-content-center h-100">
                  Permission list not found!
                </div>
              ) : (
                <PermissionTableData
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                  permissionList={permissionList}
                  getAssignedPermission={getAssignedPermission}
                  setViewAccessId={setViewAccessId}
                  getPermissionsList={getPermissionsList}
                  setPermissionNameData={setPermissionName}
                />
              )}
              {viewAccessId ? (
                <>
                  {loader ? (
                    <div className="hstack justify-content-center h-100">
                      <Spinner />
                    </div>
                  ) : (
                    <AccessCheckboxList
                      permissionName={permissionName}
                      viewAccessId={viewAccessId}
                      viewAccessList={viewAccessList}
                      getPermissionsList={getPermissionsList}
                    />
                  )}
                </>
              ) : (
                <div className="hstack justify-content-center h-100">
                  Cilck to View for view Access
                </div>
              )}
            </SplitPane>
          </SplitPane>
        </div>
      </div>
      <AddNewPermissionModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Add New Permission"}
        getPermissionsList={getPermissionsList}
        Submit={"Submit"}
      />
    </>
  );
};

export default Permission;

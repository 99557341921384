import React, { useEffect, useState } from "react";
import { Alert, Form, Spinner, Table } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { FaSquarePlus } from "react-icons/fa6";
import SplitPane from "react-split-pane";
import { studySettingControlServices } from "../../../../APIServices/studySettingControlServices";
import Pagination from "../../../../Hooks/Pagination";

const StudyQueryMessageControl = ({ tableRef, onMouseDown }) => {
  const [id, setId] = useState("");
  const [queryMessage, setQueryMessage] = useState("");
  const [queryMessageId, setQueryMessageId] = useState("");
  const [queryMessageList, setQueryMessageList] = useState([]);
  const [querySubjectList, setQuerySubjectList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [tableloader, setTableLoader] = useState(false);
  const [statusCode, setStatusCode] = useState(0);
  const [customMessage, setCustomMessage] = useState("");
  const [subjectId, setSubjectId] = useState("");

  setTimeout(() => {
    setStatusCode(0);
    setCustomMessage("");
  }, 5000);

  const getQueryMessageList = async () => {
    setTableLoader(true);
    let data = await studySettingControlServices.getQueryMessageList();
    setQueryMessageList(data.data);
    setTableLoader(false);
  };

  const getActiveQuerySubjectList = async () => {
    let data = await studySettingControlServices.getActiveQuerySubjectList();
    setQuerySubjectList(data.data);
  };

  const deleteQueryMessageData = async (e, id) => {
    e.preventDefault();
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Query Message!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    });
    if (result.isConfirmed) {
      setTableLoader(true);
      await studySettingControlServices.deleteQueryMessageData(id);
      getQueryMessageList();
      setTableLoader(false);
    }
  };

  useEffect(() => {
    getQueryMessageList();
    getActiveQuerySubjectList();
  }, []);

  const addQueryMessage = async (e) => {
    let data = {};
    setLoader(true);
    setTableLoader(true);
    data.message = queryMessage;
    data.subjectId = subjectId;
    let result = await studySettingControlServices.addQueryMessage(data);
    if (result?.statusCode === 200) {
      clearForm();
      setLoader(false);
      setTableLoader(false);
    } else {
      setLoader(false);
      setTableLoader(false);
    }
    setStatusCode(result?.statusCode);
    setCustomMessage(result.customMessage);
  };

  const updateQueryMessageData = async (e) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to Update this Query Message!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    });
    if (result.isConfirmed) {
      let data = {};
      setLoader(true);
      setTableLoader(true);
      data.id = id;
      data.message = queryMessage;
      data.subjectId = subjectId;
      let result = await studySettingControlServices.updateQueryMessageData(
        data
      );
      if (result?.statusCode === 200) {
        clearForm();
        setLoader(false);
        setTableLoader(false);
      } else {
        setLoader(false);
        setTableLoader(false);
      }
      setStatusCode(result?.statusCode);
      setCustomMessage(result.customMessage);
    }
  };

  const updateStatusQueryMessageData = async (id, status) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Status this Query Message!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    });
    if (result.isConfirmed) {
      let data = {};
      setTableLoader(true);
      data.id = id;
      data.status = status;
      let result =
        await studySettingControlServices.updateStatusQueryMessageData(data);
      if (result?.statusCode === 200) {
        clearForm();
        setTableLoader(false);
      } else {
        setTableLoader(false);
      }
    }
  };

  const updateQueryMessageDataModal = (e, id, queryMessage) => {
    setId(id);
    setQueryMessage(queryMessage);
  };

  const clearForm = () => {
    getQueryMessageList();
    setQueryMessage("");
    setQueryMessageId("");
    setSubjectId("");
  };

  const handleClick = (e, id, queryMessage, subjectId) => {
    updateQueryMessageDataModal(e, id, queryMessage);
    setQueryMessageId(id);
    setSubjectId(subjectId);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = queryMessageList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(queryMessageList?.length / itemsPerPage);

  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize={"25%"}
        style={{ height: "calc(100vh - 115px)" }}
      >
        <div className="p-2">
          <Form.Group className="mb-2">
            <div className="hstack justify-content-between">
              <Form.Label className="fw-bold mb-1">
                Query Message <span className="text-danger">*</span>
              </Form.Label>
              {queryMessageId && (
                <button
                  className="mb-1 bg-dark-subtle rounded-1 bg-opacity-10 border"
                  style={{ fontSize: 12 }}
                  onClick={() => clearForm()}
                  title="Add Query Message"
                >
                  <FaSquarePlus className="me-2" style={{ marginTop: -2 }} />
                  Add Query Message
                </button>
              )}
            </div>
            <Form.Control
              className="mb-2"
              required
              type="text"
              onChange={(e) => setQueryMessage(e.target.value)}
              value={queryMessage}
              placeholder="Enter Query Message"
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Query Subject <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select onChange={(e) => setSubjectId(e.target.value)}>
              <option>Select Subject</option>
              {querySubjectList?.map((item, index) => (
                <option
                  key={index}
                  selected={item._id === subjectId}
                  value={item._id}
                >
                  {item.subject}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <div className="text-end mb-2">
            {!queryMessageId && (
              <button
                onClick={(e) => addQueryMessage(e)}
                className="p-1 px-3 border rounded-1 text-white bg-color fs-13 text-capitalize"
                disabled={loader || !queryMessage || !subjectId}
              >
                {loader ? (
                  <Spinner
                    className="me-1"
                    style={{ width: 15, height: 15 }}
                    animation="border"
                  />
                ) : (
                  <i className="fa-solid fa-square-plus me-1"></i>
                )}
                Add Query Message
              </button>
            )}
            {queryMessageId && (
              <button
                onClick={(e) => updateQueryMessageData(e)}
                className="p-1 px-3 border rounded-1 text-white bg-color fs-13 text-capitalize"
                disabled={loader || !subjectId}
              >
                {loader ? (
                  <Spinner
                    className="me-1"
                    style={{ width: 15, height: 15 }}
                    animation="border"
                  />
                ) : (
                  <i className="fa-solid fa-pen-to-square me-1"></i>
                )}
                Update Query Message
              </button>
            )}
          </div>
          {customMessage && (
            <Alert
              className="p-0 px-2 fs-13 br3"
              variant={statusCode === 200 ? "success" : "danger"}
            >
              {customMessage}
            </Alert>
          )}
        </div>
        {tableloader ? (
          <div className="hstack justify-content-center h-100">
            <Spinner />
          </div>
        ) : result?.length === 0 || result?.length === undefined ? (
          <div className="hstack justify-content-center h-100">
            Study Query Message not found!
          </div>
        ) : (
          <>
            <Table
              id="resizable-table"
              striped
              bordered
              className="m-0 custom-table"
              ref={tableRef}
              hover
            >
              <thead className="thead-sticky">
                <tr>
                  <th
                    style={{ width: 55 }}
                    onMouseDown={(e) => onMouseDown(e, 0)}
                  >
                    S No.
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Query Message</th>
                  <th onMouseDown={(e) => onMouseDown(e, 2)}>Query Subject</th>
                  <th onMouseDown={(e) => onMouseDown(e, 3)}>Status</th>
                  <th onMouseDown={(e) => onMouseDown(e, 4)}>
                    Created Date | Time
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 5)}>
                    Updated Date | Time
                  </th>
                  <th
                    className="text-center"
                    onMouseDown={(e) => onMouseDown(e, 6)}
                    style={{ width: 70 }}
                  >
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr
                    key={index}
                    className="CP"
                    style={{
                      background: queryMessageId === item._id ? "#ccd6dd " : "",
                    }}
                  >
                    <td
                      onClick={(e) =>
                        handleClick(e, item._id, item.message, item.subjectId)
                      }
                    >
                      {startIndex + index + 1}
                    </td>
                    <td
                      onClick={(e) =>
                        handleClick(e, item._id, item.message, item.subjectId)
                      }
                    >
                      {item.message}
                    </td>
                    <td
                      onClick={(e) =>
                        handleClick(e, item._id, item.message, item.subjectId)
                      }
                    >
                      {item.subject}
                    </td>
                    <td className="p-0">
                      <Form.Select
                        onChange={(e) =>
                          updateStatusQueryMessageData(item._id, e.target.value)
                        }
                        style={{ backgroundColor: "transparent" }}
                        className="p-1 border-0 rounded-0"
                      >
                        <option>Select Status</option>
                        <option
                          selected={item.status === "active"}
                          value={"active"}
                        >
                          Active
                        </option>
                        <option
                          selected={item.status === "inactive"}
                          value={"inactive"}
                        >
                          Inactive
                        </option>
                      </Form.Select>
                    </td>
                    <td
                      onClick={(e) =>
                        handleClick(e, item._id, item.message, item.subjectId)
                      }
                    >
                      <Moment format="DD MMM YYYY | HH:mm" withTitle>
                        {item.createdAt}
                      </Moment>
                    </td>
                    <td
                      onClick={(e) =>
                        handleClick(e, item._id, item.message, item.subjectId)
                      }
                    >
                      <Moment format="DD MMM YYYY | HH:mm" withTitle>
                        {item.updatedAt}
                      </Moment>
                    </td>
                    <td
                      onClick={(e) => deleteQueryMessageData(e, item._id)}
                      className="text-center"
                    >
                      <FaTrashAlt className="delete text-danger " />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky" style={{ bottom: 0.4 }}>
              <Pagination
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={queryMessageList?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </>
        )}
      </SplitPane>
    </>
  );
};

export default StudyQueryMessageControl;

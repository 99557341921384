import React from "react";
import { Form } from "react-bootstrap";
import { designationPermissionServices } from "../../../../../APIServices/UserServices/designationPermissionServices";

const AddNewDesignationModal = ({
  getDesignationsList,
  designation,
  setDesignation,
}) => {
  const addNewDesignation = async () => {
    let userData = {};
    userData.designation = designation;
    let data = await designationPermissionServices.addNewDesignation(userData);
    if (data?.statusCode === 200) {
      setDesignation("");
      getDesignationsList();
    }
  };

  return (
    <>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Designation <span className="text-damnger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          value={designation}
          onChange={(e) => setDesignation(e.target.value)}
          placeholder="Enter Designation"
        />
      </Form.Group>

      <div className="text-end">
        <button
          className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
          disabled={!designation}
          onClick={addNewDesignation}
        >
          Add Designation
        </button>
      </div>
    </>
  );
};

export default AddNewDesignationModal;

import config from "../config/config.json";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";
import { headers } from "../APIHelpers/Headers";

export const cdiscServices = {
  addNewCDISCVariable,
  addNewSupplimentalComment,
  addNewSupplimentalMathod,
  updateSupplimentalComments,
  updateSupplimentalMathods,
  deleteSupplimentalComments,
  deleteSupplimentalMathods,
  deleteCDISCVariables,
  getCDISCVariablesList,
  getActiveVersions,
  getActiveClass,
  getActiveDomains,
  getSupplimentalMathodsList,
  getSupplimentalCommentsList,
  getActiveSupplimentalMathods,
  getActiveSupplimentalComments,
  changeSupplimentalCommentsStatus,
  changeSupplimentalMathodsStatus,
  searchSupplimentalComment,
  searchSupplimentalMathod,
  getSupplimentalsList,
  getStudySupplimentalsList,
  getValueLevelData,
  filterStudyValueLevelList,
  deleteStudyValueLevelData,

  // SDA
  getCDISCSDA,
  deleteCDISCSDA,
  updateCDISCSDA,
  importCDISCSDA,

  // STDM
  getCDISCDatasets,
  deleteStudyDataByDomainId,
  getCDISCCodeLists,
  deleteStudyDataByCodeList,

  filterValueLevelList,
  filterCDISCVariablesList,
  filterWhereClauseData,
  filterCDISCCodeLists,
  filterStudySupplimentalsList,

  getCDISCMathods,
  deleteStudyDataByMathodId,
  getCDISCComments,
  deleteStudyDataByCommentId,
  getWhereClauseData,

  // Study Value Label

  updateStudyValueLevelData,
  addNewStudyValueLevel,
  getCDISCClientUser,
  getStudyDataTemplateLockedHistory,
  getStudyDataTemplateReviewHistory,
  reviewStatusChangeUserStudyDataTemplates,
  lockStatusChangeUserStudyDataTemplates,

  generateCDISCDataEntryQuery,
  getCurrentUserDetails,
  updateCDISCSDataEntryQueryStatus,
  getDataEntryQueryDiscussionList,
  sendCDISCDataEntryQueryDiscussion,
  getDataEntryQueryList,
};

async function getDataEntryQueryList(studyId, templateId) {
  const response = await fetch(
    `${config.API_URL}getDataEntryQueryList/${studyId}/${templateId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// add New Supplimental
async function sendCDISCDataEntryQueryDiscussion(userData) {
  const response = await fetch(
    `${config.API_URL}sendCDISCDataEntryQueryDiscussion`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function getDataEntryQueryDiscussionList(studyId, queryId) {
  const response = await fetch(
    `${config.API_URL}getDataEntryQueryDiscussionList/${studyId}/${queryId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Update Client Code List
async function updateCDISCSDataEntryQueryStatus(userData) {
  const response = await fetch(
    `${config.API_URL}updateCDISCSDataEntryQueryStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function getCurrentUserDetails() {
  const response = await fetch(
    `${config.API_URL}getCurrentUser`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// add New Supplimental
async function generateCDISCDataEntryQuery(userData) {
  const response = await fetch(
    `${config.API_URL}generateCDISCDataEntryQuery`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Review Study Data Templates
async function lockStatusChangeUserStudyDataTemplates(userData) {
  const response = await fetch(
    `${config.API_URL}lockStatusChangeUserStudyDataTemplates`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Review Study Data Templates
async function reviewStatusChangeUserStudyDataTemplates(userData) {
  const response = await fetch(
    `${config.API_URL}reviewStatusChangeUserStudyDataTemplates`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Get Active Data Template
async function getStudyDataTemplateReviewHistory(id) {
  const response = await fetch(
    `${config.API_URL}getStudyDataTemplateReviewHistory/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get Active Data Template
async function getStudyDataTemplateLockedHistory(id) {
  const response = await fetch(
    `${config.API_URL}getStudyDataTemplateLockedHistory/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getCDISCClientUser(studyId) {
  const response = await fetch(
    `${config.API_URL}getCDISCClientUser/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Add New Study Value Level
async function addNewStudyValueLevel(userData) {
  const response = await fetch(
    `${config.API_URL}addNewStudyValueLevel`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Study Value Level Data
async function updateStudyValueLevelData(userData) {
  const response = await fetch(
    `${config.API_URL}updateStudyValueLevelData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Filter Variable List
async function filterCDISCVariablesList(userData) {
  try {
    const response = await fetch(
      `${config.API_URL}filterCDISCVariablesList`,
      headersWithAuth("POST", userData, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

// Filter Variable List
async function filterWhereClauseData(userData) {
  try {
    const response = await fetch(
      `${config.API_URL}filterWhereClauseData`,
      headersWithAuth("POST", userData, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

// Filter Variable List
async function filterCDISCCodeLists(userData) {
  try {
    const response = await fetch(
      `${config.API_URL}filterCDISCCodeLists`,
      headersWithAuth("POST", userData, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

// Filter Variable List
async function filterStudySupplimentalsList(userData) {
  try {
    const response = await fetch(
      `${config.API_URL}filterStudySupplimentalsList`,
      headersWithAuth("POST", userData, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}
// Filter Value Label List
async function filterValueLevelList(userData) {
  try {
    const response = await fetch(
      `${config.API_URL}filterValueLevelList`,
      headersWithAuth("POST", userData, headers)
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

// Get Where Clause Data
async function getWhereClauseData(studyId) {
  const response = await fetch(
    `${config.API_URL}getWhereClauseData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get CDISC Method
async function getCDISCComments(studyId) {
  const response = await fetch(
    `${config.API_URL}getCDISCComments/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Method
async function deleteStudyDataByCommentId(id) {
  const response = await fetch(
    `${config.API_URL}deleteStudyDataByCommentId/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get CDISC Method
async function getCDISCMathods(studyId) {
  const response = await fetch(
    `${config.API_URL}getCDISCMathods/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Method
async function deleteStudyDataByMathodId(id) {
  const response = await fetch(
    `${config.API_URL}deleteStudyDataByMathodId/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get CDISC Code List
async function getCDISCCodeLists(studyId) {
  const response = await fetch(
    `${config.API_URL}getCDISCCodeLists/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Code List
async function deleteStudyDataByCodeList(id) {
  const response = await fetch(
    `${config.API_URL}deleteStudyDataByCodeList/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get CDISC STDM
async function getCDISCDatasets(studyId) {
  const response = await fetch(
    `${config.API_URL}getCDISCDatasets/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete CDISC STDM
async function deleteStudyDataByDomainId(id) {
  const response = await fetch(
    `${config.API_URL}deleteStudyDataByDomainId/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get CDISC SDA
async function getCDISCSDA(studyId) {
  const response = await fetch(
    `${config.API_URL}getCDISCSDA/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// import CDISC SDA
async function importCDISCSDA(userData) {
  const response = await fetch(
    `${config.API_URL}importCDISCSDA`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update SDA
async function updateCDISCSDA(userData) {
  const response = await fetch(
    `${config.API_URL}updateCDISCSDA`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Delete SDA
async function deleteCDISCSDA(id) {
  const response = await fetch(
    `${config.API_URL}deleteCDISCSDA/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Add Sponser
async function addNewCDISCVariable(userData) {
  const response = await fetch(
    `${config.API_URL}addNewCDISCVariable`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Add Project
async function addNewSupplimentalMathod(userData) {
  const response = await fetch(
    `${config.API_URL}addNewSupplimentalMathod`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Add Project
async function addNewSupplimentalComment(userData) {
  const response = await fetch(
    `${config.API_URL}addNewSupplimentalComment`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Add Project
async function searchSupplimentalComment(userData) {
  const response = await fetch(
    `${config.API_URL}searchSupplimentalComment`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}
// Add Project
async function searchSupplimentalMathod(userData) {
  const response = await fetch(
    `${config.API_URL}searchSupplimentalMathod`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

// Update Sponser
async function updateSupplimentalComments(userData) {
  const response = await fetch(
    `${config.API_URL}updateSupplimentalComments`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Update Project
async function updateSupplimentalMathods(userData) {
  const response = await fetch(
    `${config.API_URL}updateSupplimentalMathods`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

async function changeSupplimentalCommentsStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changeSupplimentalCommentsStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Change Project Status
async function changeSupplimentalMathodsStatus(userData) {
  const response = await fetch(
    `${config.API_URL}changeSupplimentalMathodsStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Get All sponser for Table
async function getCDISCVariablesList(studyId) {
  const response = await fetch(
    `${config.API_URL}getCDISCVariablesList/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All sponser for Table
async function getActiveVersions() {
  const response = await fetch(
    `${config.API_URL}getActiveVersions`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All sponser for Table
async function getActiveClass(versionId) {
  const response = await fetch(
    `${config.API_URL}getActiveClass/${versionId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All sponser for Table
async function getActiveDomains(classId) {
  const response = await fetch(
    `${config.API_URL}getActiveDomains/${classId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All project List for Table
async function getSupplimentalCommentsList() {
  const response = await fetch(
    `${config.API_URL}getSupplimentalCommentsList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All project List for Table
async function getSupplimentalMathodsList() {
  const response = await fetch(
    `${config.API_URL}getSupplimentalMathodsList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All project List for Table
async function getSupplimentalsList() {
  const response = await fetch(
    `${config.API_URL}getSupplimentalsList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All project List for Table
async function getStudySupplimentalsList(studyId) {
  const response = await fetch(
    `${config.API_URL}getStudySupplimentalsList/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Get All Value Level Data
async function getValueLevelData(id) {
  const response = await fetch(
    `${config.API_URL}getStudyValueLevelData/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Filter Value Level
async function filterStudyValueLevelList(userData) {
  const response = await fetch(
    `${config.API_URL}filterStudyValueLevelList`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Get All project List for Table
async function getActiveSupplimentalMathods() {
  const response = await fetch(
    `${config.API_URL}getActiveSupplimentalMathods`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getActiveSupplimentalComments() {
  const response = await fetch(
    `${config.API_URL}getActiveSupplimentalComments`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

// Delete Sponser
async function deleteSupplimentalComments(id) {
  const response = await fetch(
    `${config.API_URL}deleteSupplimentalComments/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Value Level Data
async function deleteStudyValueLevelData(id) {
  const response = await fetch(
    `${config.API_URL}deleteStudyValueLevelData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Project
async function deleteSupplimentalMathods(id) {
  const response = await fetch(
    `${config.API_URL}deleteSupplimentalMathods/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

// Delete Study
async function deleteCDISCVariables(id) {
  const response = await fetch(
    `${config.API_URL}deleteCDISCVariables/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data;
}

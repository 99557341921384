import React, { useState } from "react";
import {
  Alert,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { authServices } from "../../APIServices/authServices";
import { NavLink } from "react-router-dom";
import { LuClipboardSignature } from "react-icons/lu";
import { PiPassword } from "react-icons/pi";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [password, setPassword] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() !== false) {
      e.stopPropagation();
      let userData = {};
      userData.email = email;
      userData.password = password;
      userData.roles = ["cdisc", "cdiscuser"];
      let data = await authServices.login(userData);
      setStatusMessage(data);
      setLoader(false);
      setTimeout(() => {
        setStatusMessage("");
      }, 3000);
    }
    setValidated(true);
  };
  return (
    <>
      <div
        className="w-100 hstack justify-content-center loginPageBgImage"
        style={{ height: "100vh" }}
      >
        <Container>
          <Row>
            <Form.Group as={Col} md={6}></Form.Group>
            <Form.Group as={Col} md={6} className="hstack justify-content-end">
              <Card
                style={{ width: "30rem" }}
                className="border rounded-1 shadow-sm p-3"
              >
                <Card.Body>
                  <Card.Title className="fw-bold text-center">Login</Card.Title>
                  <Card.Text>
                    <Form.Group className="mb-2">
                      <Form.Label className="mb-1 fw-bold">
                        Email Id <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        required
                        placeholder="Enter Email Id"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 fw-bold">
                        Password <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          required
                          placeholder="Enter Password"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div
                          className="passwordvisiability"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <i
                              className="fa-regular fa-eye-slash fs-13"
                              title="Hide Password"
                            ></i>
                          ) : (
                            <i
                              className="fa-regular fa-eye fs-13"
                              title="Revel Password"
                            ></i>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <button
                        className={`w-100 hstack gap-1 justify-content-center tabButtons border rounded-1 fs-13 p-1`}
                        disabled={loader || !email || !password}
                        validated={validated}
                        onClick={(e) => handleSubmit(e)}
                      >
                        <div className="fw-bold">
                          {loader && (
                            <Spinner style={{ width: 14, height: 14 }} />
                          )}{" "}
                          Login
                        </div>
                      </button>
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <div className="hstack justify-content-between">
                        <NavLink
                          to={"/forget-password"}
                          className="fs-13 text-dark"
                        >
                          Forget Password <PiPassword />
                        </NavLink>
                        <NavLink to={"/signup"} className="fs-13 text-dark">
                          SignUp <LuClipboardSignature />
                        </NavLink>
                      </div>
                    </Form.Group>
                    {statusMessage && (
                      <Alert
                        className="fs-10 py-1 px-1 m-0"
                        variant={
                          statusMessage?.statusCode === 200
                            ? "success"
                            : "danger"
                        }
                      >
                        {statusMessage?.customMessage}
                      </Alert>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Form.Group>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Login;

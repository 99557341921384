import React, { useEffect, useState } from "react";
import AddStudySupplementary from "./AddStudySupplementary";
import SplitPane from "react-split-pane";
import { Spinner } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import StudySupplementaryTableData from "./StudySupplementaryTableData";
import UseToggle from "../../../../Hooks/UseToggle";
import StudySupplementaryFilterData from "./StudySupplementaryFilter";
import { BiCollapse, BiExpand } from "react-icons/bi";
import { CiFilter, CiViewTable } from "react-icons/ci";
import { TbLayoutSidebarRightExpand } from "react-icons/tb";
import StudySupplementaryTableProperties from "./StudySupplementaryTableProperties";
import { cdiscServices } from "../../../../APIServices/cdiscServices";
import { sponserServices } from "../../../../APIServices/sponserServices";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";

const StudySupplementary = ({ onMouseDown, tableRef }) => {
  const [loader, setLoader] = useState(false);
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [addNewStudySupplementarydata, setaddNewStudySupplementarydata] =
    useState([]);
  const [studySupplementaryData, setstudySupplementaryData] = useState("");
  const [versionId, setVersionId] = useState("");
  const [filter, setFilter] = useState(false);
  const currentUrl = window.location.href;
  const splitUrl = currentUrl?.split("/");
  const studyId = splitUrl[4];

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const getSupplimentalsList = async () => {
    setLoader(true);
    let data = await cdiscServices.getStudySupplimentalsList(studyId);
    setaddNewStudySupplementarydata(data?.data);
    setLoader(false);
  };

  const findStudyDetails = async (studyId) => {
    let data = await sponserServices.findStudyDetails(studyId)
    setVersionId(data?.data?.version)
  }

  const HeaderTools = [
    {
      title: isFormOpen ? "Hide Form" : "Add StudySupplementary",
      icon: isFormOpen ? <TbLayoutSidebarRightExpand /> : <AiOutlinePlus />,
      onClick: () => setIsFormOpen(!isFormOpen),
    },

    {
      title: "View Table Properties",
      icon: <CiViewTable />,
      onClick: setToggle,
    },
    { title: "Filter", icon: !filter ? <MdFilterAlt /> : <MdFilterAltOff />, onClick: ()=> {setToggle1(); setFilter(false)} },
  ];

  useEffect(() => {
    getSupplimentalsList();
  }, []);

  useEffect(() => {
    findStudyDetails(studyId);
  }, [studyId]);

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div
        className="hstack justify-content-between w-100 border-bottom"
        style={{ padding: "4.6px 9px" }}
      >
        <div className="hstack gap-2">
          {HeaderTools?.map((button, index) => (
            <React.Fragment key={index}>
              <button
                title={button.title}
                className="tabButtons border rounded-1 fs-13"
                style={{ padding: "1px 5px" }}
                onClick={button.onClick}
              >
                {button.icon}
              </button>
              {index < 2 && <div className="vr"></div>}
            </React.Fragment>
          ))}
        </div>
        <button
          className="tabButtons border rounded-1 fs-13"
          title={!isFullScreen ? "Full Mode" : "Original Mode"}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <BiCollapse /> : <BiExpand />}
        </button>
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize={"100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={isFormOpen ? "20%" : "100%"}
          >
            {isFormOpen && (
              <AddStudySupplementary
                versionId={versionId}
                studySupplementaryData={studySupplementaryData}
                setstudySupplementaryData={setstudySupplementaryData}
                getSupplimentalsList={getSupplimentalsList}
              />
            )}
            <>
              {loader ? (
                <div className="hstack justify-content-center w-100 h-100">
                  <Spinner />
                </div>
              ) : addNewStudySupplementarydata?.length === 0 ? (
                <div className="hstack justify-content-center w-100 h-100">
                  Study Supplementary Data Not Found!
                </div>
              ) : (
                <StudySupplementaryTableData
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                  setIsFormOpen={setIsFormOpen}
                  addNewStudySupplementarydata={addNewStudySupplementarydata}
                  setSupplimentaryData={setstudySupplementaryData}
                  getSupplimentalsList={getSupplimentalsList}
                />
              )}
            </>
          </SplitPane>
        </SplitPane>
      </div>
      <StudySupplementaryTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
      <StudySupplementaryFilterData
        Show={!toggle1}
        Hide={setToggle1}
        Title={"Filter StudySupplementary"}
        studyId={studyId}
        filter={filter}
        setFilter={setFilter}
        getSupplimentalsList={getSupplimentalsList}
        setaddNewStudySupplementarydata={setaddNewStudySupplementarydata}
        addNewStudySupplementarydata={addNewStudySupplementarydata}
      />
    </div>
  );
};

export default StudySupplementary;

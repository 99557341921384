import React from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import SplitPane from "react-split-pane";

const PropertyTable = ({ crfProperties }) => {

  return (
    <>
      <SplitPane split="vertical" minSize={20} defaultSize="100%">
        <div>
          <Table
            id="resizable-table"
            striped
            hover
            bordered
            className="m-0 custom-table"
          >
            <thead className="position-sticky" style={{ top: -1 }}>
              <tr>
                <th style={{ width: "200px" }}>Attribute</th>
                <th>Value</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Dataset Name</td>
                <td>{crfProperties?.datasetName}</td>
              </tr>
              <tr>
                <td>Dataset Label</td>
                <td>{crfProperties?.datasetLabel}</td>
              </tr>
              <tr>
                <td>Import Date | Time</td>
                <td>
                  <Moment format="DD MMM YYYY | HH:mm:ss">
                    {crfProperties?.createdAt}
                  </Moment>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </SplitPane>
    </>
  );
};

export default PropertyTable;

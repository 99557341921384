import React from "react";
import { Table } from "react-bootstrap";
import { useParams } from "react-router";

const Activity = ({ onMouseDown, tableRef }) => {
  const ref = useParams();
  return (
    <div className="overflow-auto p-2">
      <Table
        id="resizable-table"
        striped
        hover
        className="m-0 custom-table"
        ref={tableRef}
      >
        <thead>
          <tr>
            <th
              onMouseDown={(e) => onMouseDown(e, 0)}
              style={{ width: 50 }}
              className="text-center"
            >
              Sr.
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 1)}>Status</th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>Type</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>Activities</th>
            <th onMouseDown={(e) => onMouseDown(e, 4)}>Categories</th>
            <th onMouseDown={(e) => onMouseDown(e, 5)}>Activity By</th>
            <th onMouseDown={(e) => onMouseDown(e, 6)}>Activity Date</th>
            <th onMouseDown={(e) => onMouseDown(e, 7)}>Activity Time</th>
          </tr>
        </thead>
        <tbody ref={ref}>
          <tr>
            <td className="text-center">1</td>
            <td>dsfgh</td>
            <td>dsfgh</td>
            <td>dsfgh</td>
            <td>dsfgh</td>
            <td>dsfgh</td>
            <td>dsfgh</td>
            <td>dsfgh</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default Activity;

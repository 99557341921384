import React, { useEffect, useState } from "react";
import { Form, Spinner, Table } from "react-bootstrap";
import SplitPane from "react-split-pane";
import { supplimentaryServices } from "../../../../APIServices/Supplementary/sdtmServices";
import Swal from "sweetalert2";
import { FaPlusSquare } from "react-icons/fa";
import { LuDownload } from "react-icons/lu";
import UseToggle from "../../../../Hooks/UseToggle";
import LegacyPopup from "./LegacyPopup";

const DatasetTable = ({
  tableRef,
  studyId,
  uniqueId,
  isDataFormOpen,
  setMode,
  mode,
  setIsDataFormOpen,
  viewType,
  dataEntrystatus,
}) => {
  const [studyDataTemplateRow, setStudyDataTemplateRow] = useState([]);
  const [studyDataTemplateColumn, setStudyDataTemplateColumn] = useState([]);
  const [loader, setLoader] = useState(false);
  const { toggle, setToggle } = UseToggle("");
  const [selectFormat, setSelectFormat] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const [editableRow, setEditableRow] = useState(null);
  const [updatedData, setUpdatedData] = useState({});
  const [type, setType] = useState("");
  const getStudyDataTemplateEntryData = async (studyId, uniqueId) => {
    setLoader(true);
    let data = await supplimentaryServices.getStudyDataTemplateEntryData(
      studyId,
      uniqueId
    );
    setStudyDataTemplateRow(data?.rows || []);
    setStudyDataTemplateColumn(data?.columns || []);
    setLoader(false);
  };
  useEffect(() => {
    getStudyDataTemplateEntryData(studyId, uniqueId);
  }, [studyId, uniqueId]);

  const deleteStudyDataTemplateEntryData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Row!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.uniqueId = uniqueId;
        userData.rowId = id;
        let results =
          await supplimentaryServices.deleteStudyDataTemplateEntryData(
            userData
          );
        if (results?.statusCode === 200) {
          getStudyDataTemplateEntryData(studyId, uniqueId);
        }
      }
    });
  };

  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    if (studyDataTemplateRow && studyDataTemplateRow[0]) {
      const initialFormValues = Object.keys(studyDataTemplateRow[0]).reduce(
        (acc, key) => {
          acc[key] = "";
          return acc;
        },
        {}
      );
      setFormValues(initialFormValues);
    }
  }, [studyDataTemplateRow]);

  const handleInputChange = (key, value) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [key]: value,
    }));
  };

  const addDataTemplateEntryData = async () => {
    let userData = {};
    userData.uniqueId = uniqueId;
    userData.rowData = formValues;
    let data = await supplimentaryServices.addDataTemplateEntryData(userData);
    if (data?.statusCode === 200) {
      getStudyDataTemplateEntryData(studyId, uniqueId);
    }
  };

  const updateStudyDataEntryData = async () => {
    let userData = {};
    userData.uniqueId = uniqueId;
    userData.rowData = formValues;
    let data = await supplimentaryServices.updateStudyDataEntryData(userData);
    if (data?.statusCode === 200) {
      getStudyDataTemplateEntryData(studyId, uniqueId);
    }
  };

  const handleClickForUpdateData = async (uniqueId) => {
    setMode("update");
    setIsDataFormOpen(true);
    setFormValues(
      studyDataTemplateRow?.find((data) => data.uniqueId === uniqueId)
    );
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const csvType = file.type === "text/csv";
    const excelType =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (
      (selectFormat === "csv" && csvType) ||
      (selectFormat === "excel" && excelType)
    )
      setUploadFile(file);
    else {
      setUploadFile(null);
      alert(`Please select a ${selectFormat.toUpperCase()} file.`);
    }
  };

  const uploadFileinCRFRowData = async () => {
    if (uploadFile) {
      const formData = new FormData();
      formData.append("file", uploadFile);
      formData.append("uniqueId", uniqueId);
      let result = await supplimentaryServices.uploadFileinCRFRowData(formData);
      if (result?.statusCode === 200) {
        setUploadFile(null);
        getStudyDataTemplateEntryData(studyId, uniqueId);
      }
    }
  };

  const handleFormatChange = (format) => {
    setSelectFormat(format);
    setUploadFile(null);
  };

  const handleDoubleClick = (rowIndex, key) => {
    setEditableRow({ rowIndex, key });
  };

  const handleDoubleClickPopup = () => {
    setToggle();
  };

  const handleChange = async (e, rowIndex, key, uniqueId) => {
    const { value } = e.target;
    const updatedUserData = {
      ...updatedData,
      [rowIndex]: {
        ...updatedData[rowIndex],
        [key]: value,
      },
    };

    setUpdatedData(updatedUserData);
    let userData = {};
    userData.uniqueId = uniqueId;
    userData.rowData = updatedUserData;
    // let data = await supplimentaryServices.updateStudyDataEntryData(userData);
    // if (data?.statusCode === 200) {
    //   getStudyDataTemplateEntryData(studyId, uniqueId);
    // }
  };

  const handleKeyPress = (e, rowIndex) => {
    if (e.key === "Enter") {
      setEditableRow(null);
    }
  };

  const exportDataTemplateFileMultiFormat = async () => {
    let userData = {};
    userData.uniqueId = uniqueId;
    await supplimentaryServices.exportDataTemplateFileMultiFormat(userData);
  };

  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        style={{ height: "89%" }}
        defaultSize={
          isDataFormOpen
            ? dataEntrystatus === "locked"
              ? "100%"
              : "20%"
            : "100%"
        }
      >
        {isDataFormOpen && dataEntrystatus === "unLocked" && (
          <div>
            {mode === "add" && (
              <Form.Group className="mb-2">
                <Form.Label className="mb-1 fw-bold text-capitalize">
                  Type
                </Form.Label>
                <Form.Select onChange={(e) => setType(e.target.value)}>
                  <option value="">Select Type</option>
                  <option selected={type === "manual"} value="manual">
                    Manual Entry
                  </option>
                  <option selected={type === "file"} value="file">
                    By File
                  </option>
                </Form.Select>
              </Form.Group>
            )}
            {(mode === "update" ? "manual" : type) === "manual" && (
              <>
                {mode === "add"
                  ? studyDataTemplateColumn.map((item, index) => (
                      <Form.Group key={index} className="mb-2">
                        <Form.Label className="mb-1 fw-bold text-capitalize">
                          {item?.name}
                        </Form.Label>
                        <Form.Control
                          type={item?.type}
                          placeholder={`Enter ${item?.label}`}
                          value={formValues[item?.name]}
                          onChange={(e) =>
                            handleInputChange(item?.name, e.target.value)
                          }
                        />
                      </Form.Group>
                    ))
                  : mode === "update" &&
                    studyDataTemplateColumn.map((item, index) => (
                      <Form.Group key={index} className="mb-2">
                        <Form.Label className="mb-1 fw-bold text-capitalize">
                          {item?.name}
                        </Form.Label>
                        <Form.Control
                          type={item?.type}
                          placeholder={`Enter ${item?.label}`}
                          value={formValues[item?.name]}
                          onChange={(e) =>
                            handleInputChange(item?.name, e.target.value)
                          }
                        />
                      </Form.Group>
                    ))}
                <Form.Group className="text-end">
                  {mode === "add" ? (
                    <button
                      onClick={addDataTemplateEntryData}
                      className="p-1 px-3 border rounded-1 text-capitalize text-white bg-color fs-13"
                    >
                      <FaPlusSquare style={{ marginBottom: 2 }} /> Add Data
                    </button>
                  ) : (
                    <button
                      onClick={updateStudyDataEntryData}
                      className="p-1 px-3 border rounded-1 text-capitalize text-white bg-color fs-13"
                    >
                      <FaPlusSquare style={{ marginBottom: 2 }} /> Update Data
                    </button>
                  )}
                </Form.Group>
              </>
            )}
            {type === "file" && mode === "add" && (
              <>
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1 fw-bold">
                    Format <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => handleFormatChange(e.target.value)}
                    required
                  >
                    <option value="">Select Format</option>
                    <option selected={selectFormat === "csv"} value="csv">
                      CSV
                    </option>
                    <option selected={selectFormat === "excel"} value="excel">
                      Excel
                    </option>
                  </Form.Select>
                </Form.Group>
                {selectFormat && (
                  <Form.Group className="mb-1">
                    <Form.Label className="mb-1 fw-bold">
                      Upload Template <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      onChange={handleFileChange}
                      accept={selectFormat === "csv" ? ".csv" : ".xls, .xlsx"}
                      required
                    />
                    <div className="text-end">
                      <Form.Label
                        onClick={exportDataTemplateFileMultiFormat}
                        className="mb-0 fw-bold fs-10 cursor-pointer"
                        title="Download Template"
                      >
                        <LuDownload style={{ marginBottom: 2 }} /> Download CSV
                        Template
                      </Form.Label>
                    </div>
                  </Form.Group>
                )}
                <div className="text-end">
                  <button
                    onClick={
                      type === "file"
                        ? uploadFileinCRFRowData
                        : addDataTemplateEntryData
                    }
                    className="p-1 px-3 border rounded-1 text-capitalize text-white bg-color fs-13"
                  >
                    <FaPlusSquare style={{ marginBottom: 2 }} /> Add Data
                  </button>
                </div>
              </>
            )}
          </div>
        )}
        {loader ? (
          <div className="hstack justify-content-center h-100 w-100 py-5">
            <Spinner />
          </div>
        ) : studyDataTemplateRow?.length === 0 ? (
          <div className="hstack justify-content-center h-100 w-100 py-5 fs-13">
            Datasets not Found
          </div>
        ) : (
          <div className="overflow-auto">
            <Table
              id="resizable-table"
              ref={tableRef}
              striped
              hover
              bordered
              className="m-0 custom-table"
            >
              <thead className="position-sticky" style={{ top: -1 }}>
                <tr>
                  {studyDataTemplateColumn?.map((column, index) => (
                    <th key={index}>
                      {viewType === "name" ? column?.name : column?.label}
                    </th>
                  ))}
                  {dataEntrystatus === "unLocked" && (
                    <>
                      <th className="text-center" style={{ width: 70 }}>
                        Update
                      </th>
                      <th className="text-center" style={{ width: 60 }}>
                        Delete
                      </th>
                    </>
                  )}
                </tr>
              </thead>

              <tbody>
                {studyDataTemplateRow?.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {Object.entries(row).map(([key, value], colIndex) =>
                      key === "uniqueId" ? null : (
                        <td
                          className=" px-2"
                          key={colIndex}
                          onDoubleClick={() =>
                            value
                              ? handleDoubleClick(rowIndex, key)
                              : handleDoubleClickPopup(row.uniqueId)
                          }
                        >
                          {editableRow?.rowIndex === rowIndex &&
                          editableRow?.key === key ? (
                            <Form.Control
                              className="border-0 rounded-0 bg-transparent p-0"
                              type="text"
                              value={updatedData[rowIndex]?.[key] || value}
                              onChange={(e) =>
                                handleChange(e, rowIndex, key, row.uniqueId)
                              }
                              onKeyPress={(e) => handleKeyPress(e, rowIndex)}
                              onBlur={() => setEditableRow(null)}
                              autoFocus
                            />
                          ) : (
                            value
                          )}
                        </td>
                      )
                    )}
                    {dataEntrystatus === "unLocked" && (
                      <>
                        <td className="text-center">
                          <button
                            title="Update"
                            onClick={() =>
                              handleClickForUpdateData(row.uniqueId)
                            }
                            className="text-white border-primary bg-primary border-0 fs-10 br3"
                          >
                            Update
                          </button>
                        </td>
                        <td>
                          <div
                            onClick={() =>
                              deleteStudyDataTemplateEntryData(row.uniqueId)
                            }
                            className="text-center cursor-pointer"
                            title="Delete Data Template"
                          >
                            <i
                              className={`fa-solid fa-trash-can text-danger`}
                            ></i>
                          </div>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </SplitPane>
      <LegacyPopup Show={!toggle} Hide={setToggle} Title={"Date"} />
    </>
  );
};

export default DatasetTable;

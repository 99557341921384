import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { designationPermissionServices } from "../../../../../APIServices/UserServices/designationPermissionServices";

const AccessCheckboxList = ({
  viewAccessId,
  viewAccessList,
  getPermissionsList,
  permissionName,
}) => {
  let [checkboxes, setCheckboxes] = useState({
    //Dashboard Module
    dashboardModuleData: false,

    //Package
    SyntaxAddPackage: false,

    //Consultant Module
    addNewConsultants: false,
    viewConsultantsDetails: false,
    updateConsultantsDetails: false,
    changeConsultantStatus: false,
    exportAllConsultantData: false,
    viewConsultantInvitationHistory: false,
    sendNewConsultantInvitation: false,
    resendConsultantInvitation: false,
    cancelConsultantInvitation: false,

    //Organization Module
    addNewOrganizations: false,
    viewOrganizationsDetails: false,
    updateOrganizationsDetails: false,
    changeOrganizationStatus: false,
    exportAllOrganizationData: false,
    viewOrganizationInvitationHistory: false,
    sendNewOrganizationInvitation: false,
    resendOrganizationInvitation: false,
    cancelOrganizationInvitation: false,

    //Developer Module
    addNewDevelopers: false,
    viewDevelopersDetails: false,
    updateDevelopersDetails: false,
    changeDeveloperStatus: false,
    exportAllDeveloperData: false,
    viewDeveloperInvitationHistory: false,
    sendNewDeveloperInvitation: false,
    resendDeveloperInvitation: false,
    cancelDeveloperInvitation: false,

    //AIUser Module
    addNewAIUsers: false,
    viewAIUsersDetails: false,
    updateAIUsersDetails: false,
    changeAIUserStatus: false,
    exportAllAIUserData: false,
    viewAIUserInvitationHistory: false,
    sendNewAIUserInvitation: false,
    resendAIUserInvitation: false,
    cancelAIUserInvitation: false,
  });

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes({
      ...checkboxes,
      [name]: checked,
    });
  };

  const assignPermissionLists = async () => {
    let userData = {};
    userData.id = viewAccessId;
    userData.permissionLists = checkboxes;
    let data = await designationPermissionServices.assignPermissionLists(
      userData
    );
    if (data?.statusCode === 200) {
      getPermissionsList();
    }
  };

  useEffect(() => {
    setCheckboxes(viewAccessList);
  }, [viewAccessList]);
  return (
    <>
      <div className="border-bottom mb-2">
        <p className="text-center mb-2">
          {permissionName} Permission Level Lists
        </p>
      </div>
      <div className="permissionList_height">
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <p className="mb-1">Dashboard Graph & Data:</p>
              <div className="moduleGroup">
                <Form.Check
                  type="checkbox"
                  label="All Data"
                  name="dashboardModuleData"
                  id="dashboardModuleData"
                  checked={checkboxes?.dashboardModuleData}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <p className="mb-1">Package:</p>
              <div className="moduleGroup">
                <Form.Check
                  type="checkbox"
                  label="Admin Validator"
                  name="SyntaxAddPackage"
                  id="SyntaxAddPackage"
                  checked={checkboxes?.SyntaxAddPackage}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <p className="mb-1">Organizations Module:</p>
              <Row>
                <Col md={12}>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Add New Organizations"
                      name="addNewOrganizations"
                      id="addNewOrganizations"
                      checked={checkboxes?.addNewOrganizations}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="View Details"
                      name="viewOrganizationsDetails"
                      id="viewOrganizationsDetails"
                      checked={checkboxes?.viewOrganizationsDetails}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Update Details"
                      name="updateOrganizationsDetails"
                      id="updateOrganizationsDetails"
                      checked={checkboxes?.updateOrganizationsDetails}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Change Status"
                      name="changeOrganizationStatus"
                      id="changeOrganizationStatus"
                      checked={checkboxes?.changeOrganizationStatus}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Export All Organization Data"
                      name="exportAllOrganizationData"
                      id="exportAllOrganizationData"
                      checked={checkboxes?.exportAllOrganizationData}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="View Invitation History"
                      name="viewOrganizationInvitationHistory"
                      id="viewOrganizationInvitationHistory"
                      checked={checkboxes?.viewOrganizationInvitationHistory}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Send New Invitation"
                      name="sendNewOrganizationInvitation"
                      id="sendNewOrganizationInvitation"
                      checked={checkboxes?.sendNewOrganizationInvitation}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Resend Invitation"
                      name="resendOrganizationInvitation"
                      id="resendOrganizationInvitation"
                      checked={checkboxes?.resendOrganizationInvitation}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Cancel Invitation"
                      name="cancelOrganizationInvitation"
                      id="cancelOrganizationInvitation"
                      checked={checkboxes?.cancelOrganizationInvitation}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <p className="mb-1">Consultants Module:</p>
              <Row>
                <Col md={12}>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Add New Consultants"
                      name="addNewConsultants"
                      id="addNewConsultants"
                      checked={checkboxes?.addNewConsultants}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="View Details"
                      name="viewConsultantsDetails"
                      id="viewConsultantsDetails"
                      checked={checkboxes?.viewConsultantsDetails}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Update Details"
                      name="updateConsultantsDetails"
                      id="updateConsultantsDetails"
                      checked={checkboxes?.updateConsultantsDetails}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Change Status"
                      name="changeConsultantStatus"
                      id="changeConsultantStatus"
                      checked={checkboxes?.changeConsultantStatus}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Export All Consultant Data"
                      name="exportAllConsultantData"
                      id="exportAllConsultantData"
                      checked={checkboxes?.exportAllConsultantData}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="View Invitation History"
                      name="viewConsultantInvitationHistory"
                      id="viewConsultantInvitationHistory"
                      checked={checkboxes?.viewConsultantInvitationHistory}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Send New Invitation"
                      name="sendNewConsultantInvitation"
                      id="sendNewConsultantInvitation"
                      checked={checkboxes?.sendNewConsultantInvitation}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Resend Invitation"
                      name="resendConsultantInvitation"
                      id="resendConsultantInvitation"
                      checked={checkboxes?.resendConsultantInvitation}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Cancel Invitation"
                      name="cancelConsultantInvitation"
                      id="cancelConsultantInvitation"
                      checked={checkboxes?.cancelConsultantInvitation}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <p className="mb-1">Developers Module:</p>
              <Row>
                <Col md={12}>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Add New Developers"
                      name="addNewDevelopers"
                      id="addNewDevelopers"
                      checked={checkboxes?.addNewDevelopers}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="View Details"
                      name="viewDevelopersDetails"
                      id="viewDevelopersDetails"
                      checked={checkboxes?.viewDevelopersDetails}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Update Details"
                      name="updateDevelopersDetails"
                      id="updateDevelopersDetails"
                      checked={checkboxes?.updateDevelopersDetails}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Change Status"
                      name="changeDeveloperStatus"
                      id="changeDeveloperStatus"
                      checked={checkboxes?.changeDeveloperStatus}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Export All Developer Data"
                      name="exportAllDeveloperData"
                      id="exportAllDeveloperData"
                      checked={checkboxes?.exportAllDeveloperData}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="View Invitation History"
                      name="viewDeveloperInvitationHistory"
                      id="viewDeveloperInvitationHistory"
                      checked={checkboxes?.viewDeveloperInvitationHistory}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Send New Invitation"
                      name="sendNewDeveloperInvitation"
                      id="sendNewDeveloperInvitation"
                      checked={checkboxes?.sendNewDeveloperInvitation}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Resend Invitation"
                      name="resendDeveloperInvitation"
                      id="resendDeveloperInvitation"
                      checked={checkboxes?.resendDeveloperInvitation}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Cancel Invitation"
                      name="cancelDeveloperInvitation"
                      id="cancelDeveloperInvitation"
                      checked={checkboxes?.cancelDeveloperInvitation}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <p className="mb-1">AI Users Module:</p>
              <Row>
                <Col md={12}>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Add New AIUsers"
                      name="addNewAIUsers"
                      id="addNewAIUsers"
                      checked={checkboxes?.addNewAIUsers}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="View Details"
                      name="viewAIUsersDetails"
                      id="viewAIUsersDetails"
                      checked={checkboxes?.viewAIUsersDetails}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Update Details"
                      name="updateAIUsersDetails"
                      id="updateAIUsersDetails"
                      checked={checkboxes?.updateAIUsersDetails}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Change Status"
                      name="changeAIUserStatus"
                      id="changeAIUserStatus"
                      checked={checkboxes?.changeAIUserStatus}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Export All AIUser Data"
                      name="exportAllAIUserData"
                      id="exportAllAIUserData"
                      checked={checkboxes?.exportAllAIUserData}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="View Invitation History"
                      name="viewAIUserInvitationHistory"
                      id="viewAIUserInvitationHistory"
                      checked={checkboxes?.viewAIUserInvitationHistory}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Send New Invitation"
                      name="sendNewAIUserInvitation"
                      id="sendNewAIUserInvitation"
                      checked={checkboxes?.sendNewAIUserInvitation}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Resend Invitation"
                      name="resendAIUserInvitation"
                      id="resendAIUserInvitation"
                      checked={checkboxes?.resendAIUserInvitation}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="moduleGroup">
                    <Form.Check
                      type="checkbox"
                      label="Cancel Invitation"
                      name="cancelAIUserInvitation"
                      id="cancelAIUserInvitation"
                      checked={checkboxes?.cancelAIUserInvitation}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className="text-end">
        <button
          className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
          onClick={assignPermissionLists}
        >
          Assign Permission
        </button>
      </div>
    </>
  );
};

export default AccessCheckboxList;

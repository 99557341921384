import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { designationPermissionServices } from "../../../../../APIServices/UserServices/designationPermissionServices";

const AddNewPermissionModal = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  getPermissionsList,
}) => {
  let [permission, setPermission] = useState("");

  const addNewPermission = async () => {
    let userData = {};
    userData.permission = permission;
    let data = await designationPermissionServices.addNewPermission(userData);
    if (data?.statusCode === 200) {
      closeModal();
      getPermissionsList();
    }
  };

  const closeModal = () => {
    setPermission("");
    Hide();
  };
  return (
    <Modal
      show={Show}
      onHide={closeModal}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={closeModal} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-2">
          <Form.Label className="mb-1 fw-bold">
            Permission <span>*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Enter Permission Name"
            value={permission}
            onChange={(e) => setPermission(e.target.value)}
          />
        </Form.Group>
        <div className="text-end">
          <button
            className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
            disabled={!permission}
            onClick={() => {
              addNewPermission();
            }}
          >
            {Submit}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewPermissionModal;

import React from "react";
import { Form, Modal } from "react-bootstrap";

const UpdatePermissionModal = ({
  Show,
  Hide,
  Size,
  Title,
  Submit,
  permissionName,
  setPermissionName,
  handleUpdate,
}) => {
  return (
    <Modal
      show={Show}
      onHide={Hide}
      size={Size}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{Title}</Modal.Title>
        <div onClick={() => Hide()} className="close_modal" title="Close">
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-2">
          <Form.Label className="mb-1 fw-bold">
            Permission <span>*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Enter Permission Name"
            value={permissionName}
            onChange={(e) => setPermissionName(e.target.value)}
          />
        </Form.Group>
        <div className="text-end">
          <button
            className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
            disabled={!permissionName}
            onClick={() => {
              handleUpdate();
              Hide();
            }}
          >
            {Submit}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdatePermissionModal;

import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { CiFolderOn } from "react-icons/ci";
import { HiOutlineBookOpen } from "react-icons/hi2";
import { IoFolderOpenOutline } from "react-icons/io5";
import { VscChevronDown, VscChevronRight } from "react-icons/vsc";

const Sidebar = ({ handleAddNewTab, tabs }) => {
  const [openCodeList, setOpenCodeList] = useState(false);
  const [openStudySetting, setOpenStudySetting] = useState(false);
  const [openSTDMTabulation, setOpenSTDMTabulation] = useState(false);

  return (
    <>
      <div
        className="overflow-auto border rounded-1"
        style={{ height: "calc(100vh - 80px)" }}
      >
        <div
          className={`border-bottom cursor-pointer p-1 ${
            tabs.some(
              (item) =>
                item.tabId === "studyDetailsID" && item.status === "active"
            ) && "activeMenu"
          }`}
          onClick={() =>
            handleAddNewTab(
              "Study Details",
              "fa-solid fa-book-open",
              "studyDetailsID"
            )
          }
        >
          <div className="hstack justify-content-between">
            <span>
              <HiOutlineBookOpen />
              <span className="ms-2 fs-13">Study Details</span>
            </span>
          </div>
        </div>
        <div
          className={`border-bottom cursor-pointer p-1 ${
            tabs.some(
              (item) =>
                item.tabId === "studyProtocolID" && item.status === "active"
            ) && "activeMenu"
          }`}
          onClick={() =>
            handleAddNewTab(
              "Study Protocol",
              "fa-solid fa-book-open",
              "studyProtocolID"
            )
          }
        >
          <div className="hstack justify-content-between">
            <span>
              <HiOutlineBookOpen />
              <span className="ms-2 fs-13">Study Protocol</span>
            </span>
          </div>
        </div>
        <div
          className={`text-truncate ${
            openCodeList && "activeMenu"
          } border-bottom cursor-pointer p-1`}
          onClick={() => setOpenCodeList(!openCodeList)}
          aria-expanded={openCodeList}
        >
          <div className="hstack justify-content-between">
            <span>
              {openCodeList ? <IoFolderOpenOutline /> : <CiFolderOn />}
              <span className="ms-2 fs-13">SDTM</span>
            </span>
            <span>
              {openCodeList ? <VscChevronDown /> : <VscChevronRight />}
            </span>
          </div>
        </div>
        <Collapse in={openCodeList}>
          <div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "sdtmDataDatasets" &&
                    item.status === "active"
                ) && "activeMenu"
              }`}
              title="Datasets"
              onClick={() =>
                handleAddNewTab(
                  "Datasets",
                  "fa-solid fa-book-open",
                  "sdtmDataDatasets"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Datasets</span>
                </span>
              </div>
            </div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "sdtmDataVeriable" &&
                    item.status === "active"
                ) && "activeMenu"
              }`}
              title="Veriable"
              onClick={() =>
                handleAddNewTab(
                  "Veriable",
                  "fa-solid fa-book-open",
                  "sdtmDataVeriable"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Veriable</span>
                </span>
              </div>
            </div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "sdtmDataValueLabel" &&
                    item.status === "active"
                ) && "activeMenu"
              }`}
              title="Value Label"
              onClick={() =>
                handleAddNewTab(
                  "Value Label",
                  "fa-solid fa-book-open",
                  "sdtmDataValueLabel"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Value Label</span>
                </span>
              </div>
            </div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "sdtmDataWhereClauses" &&
                    item.status === "active"
                ) && "activeMenu"
              }`}
              title="Where Clauses"
              onClick={() =>
                handleAddNewTab(
                  "Where Clauses",
                  "fa-solid fa-book-open",
                  "sdtmDataWhereClauses"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Where Clauses</span>
                </span>
              </div>
            </div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "stdmCodeListID" && item.status === "active"
                ) && "activeMenu"
              }`}
              title="Code List"
              onClick={() =>
                handleAddNewTab(
                  "Code List",
                  "fa-solid fa-book-open",
                  "stdmCodeListID"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Code List</span>
                </span>
              </div>
            </div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "stdmMethodID" && item.status === "active"
                ) && "activeMenu"
              }`}
              title="Method"
              onClick={() =>
                handleAddNewTab(
                  "Method",
                  "fa-solid fa-book-open",
                  "stdmMethodID"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Method</span>
                </span>
              </div>
            </div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "sdtmCommentId" && item.status === "active"
                ) && "activeMenu"
              }`}
              title="Comment"
              onClick={() =>
                handleAddNewTab(
                  "Comment",
                  "fa-solid fa-book-open",
                  "sdtmCommentId"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Comment</span>
                </span>
              </div>
            </div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "sdtmDataDocument" &&
                    item.status === "active"
                ) && "activeMenu"
              }`}
              title="Document"
              onClick={() =>
                handleAddNewTab(
                  "Document",
                  "fa-solid fa-book-open",
                  "sdtmDataDocument"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Document</span>
                </span>
              </div>
            </div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "sdtmDataDictionaries" &&
                    item.status === "active"
                ) && "activeMenu"
              }`}
              title="Dictionaries"
              onClick={() =>
                handleAddNewTab(
                  "Dictionaries",
                  "fa-solid fa-book-open",
                  "sdtmDataDictionaries"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Dictionaries</span>
                </span>
              </div>
            </div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "sdtmDataACRF" && item.status === "active"
                ) && "activeMenu"
              }`}
              title="ACRF"
              onClick={() =>
                handleAddNewTab("ACRF", "fa-solid fa-book-open", "sdtmDataACRF")
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">ACRF</span>
                </span>
              </div>
            </div>
            <div
              className={`text-truncate ${
                openSTDMTabulation && "activeMenu"
              } border-bottom cursor-pointer p-1 ps-3`}
              onClick={() => setOpenSTDMTabulation(!openSTDMTabulation)}
              aria-expanded={openSTDMTabulation}
            >
              <div className="hstack justify-content-between">
                <span>
                  {openSTDMTabulation ? (
                    <IoFolderOpenOutline />
                  ) : (
                    <CiFolderOn />
                  )}
                  <span className="ms-2 fs-13">tabulation</span>
                </span>
                <span>
                  {openSTDMTabulation ? (
                    <VscChevronDown />
                  ) : (
                    <VscChevronRight />
                  )}
                </span>
              </div>
            </div>
            <Collapse in={openSTDMTabulation}>
              <div>
                <div
                  className={`border-bottom cursor-pointer p-1 ps-4 ${
                    tabs.some(
                      (item) =>
                        item.tabId === "studyTabulationID" &&
                        item.status === "active"
                    ) && "activeMenu"
                  }`}
                  title="sdtm"
                  onClick={() =>
                    handleAddNewTab(
                      "sdtm",
                      "fa-solid fa-book-open",
                      "studyTabulationID"
                    )
                  }
                >
                  <div className="hstack justify-content-between">
                    <span>
                      <HiOutlineBookOpen />
                      <span className="ms-2 fs-13">sdtm</span>
                    </span>
                  </div>
                </div>
                <div
                  className={`border-bottom cursor-pointer p-1 ps-4 ${
                    tabs.some(
                      (item) =>
                        item.tabId === "stdmAdamId" && item.status === "active"
                    ) && "activeMenu"
                  }`}
                  title="adam"
                  onClick={() =>
                    handleAddNewTab(
                      "adam",
                      "fa-solid fa-book-open",
                      "stdmAdamId"
                    )
                  }
                >
                  <div className="hstack justify-content-between">
                    <span>
                      <HiOutlineBookOpen />
                      <span className="ms-2 fs-13">adam</span>
                    </span>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </Collapse>
        <div
          className={`text-truncate ${
            openStudySetting && "activeMenu"
          } border-bottom cursor-pointer p-1`}
          onClick={() => setOpenStudySetting(!openStudySetting)}
          aria-expanded={openStudySetting}
        >
          <div className="hstack justify-content-between">
            <span>
              {openStudySetting ? <IoFolderOpenOutline /> : <CiFolderOn />}
              <span className="ms-2 fs-13">Study Settings</span>
            </span>
            <span>
              {openStudySetting ? <VscChevronDown /> : <VscChevronRight />}
            </span>
          </div>
        </div>
        <Collapse in={openStudySetting}>
          <div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "studyMetadataID" && item.status === "active"
                ) && "activeMenu"
              }`}
              onClick={() =>
                handleAddNewTab(
                  "Study Metadata",
                  "fa-solid fa-book-open",
                  "studyMetadataID"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Study Metadata</span>
                </span>
              </div>
            </div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "sourceDataAnalytics" &&
                    item.status === "active"
                ) && "activeMenu"
              }`}
              title="Source Data Analytics"
              onClick={() =>
                handleAddNewTab(
                  "Source Data Analytics",
                  "fa-solid fa-book-open",
                  "sourceDataAnalytics"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">
                    Source Data Analytics (SDA)
                  </span>
                </span>
              </div>
            </div>

            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "SDTMSettings" && item.status === "active"
                ) && "activeMenu"
              }`}
              title="SDTM Settings"
              onClick={() =>
                handleAddNewTab(
                  "SDTM Settings",
                  "fa-solid fa-book-open",
                  "SDTMSettings"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">SDTM Settings</span>
                </span>
              </div>
            </div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "valueLavelId" && item.status === "active"
                ) && "activeMenu"
              }`}
              onClick={() =>
                handleAddNewTab(
                  "Value Lavel",
                  "fa-solid fa-book-open",
                  "valueLavelId"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Value Lavel</span>
                </span>
              </div>
            </div>
            <div
              className={`border-bottom cursor-pointer p-1 ps-3 ${
                tabs.some(
                  (item) =>
                    item.tabId === "studySupplimentry" &&
                    item.status === "active"
                ) && "activeMenu"
              }`}
              title="Study Supplementary"
              onClick={() =>
                handleAddNewTab(
                  "Study Supplementary",
                  "fa-solid fa-book-open",
                  "studySupplimentry"
                )
              }
            >
              <div className="hstack justify-content-between">
                <span>
                  <HiOutlineBookOpen />
                  <span className="ms-2 fs-13">Supplementary</span>
                </span>
              </div>
            </div>
          </div>
        </Collapse>
        <div
          className={`border-bottom cursor-pointer p-1 ${
            tabs.some(
              (item) => item.tabId === "legacyID" && item.status === "active"
            ) && "activeMenu"
          }`}
          onClick={() =>
            handleAddNewTab("CRF Data", "fa-solid fa-book-open", "legacyID")
          }
        >
          <div className="hstack justify-content-between">
            <span>
              <HiOutlineBookOpen />
              <span className="ms-2 fs-13">CRF Data</span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

import React, { useRef, useState } from "react";
import { Form, Table } from "react-bootstrap";
import UpdatePermissionModal from "./UpdatePermissionModal";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { designationPermissionServices } from "../../../../../APIServices/UserServices/designationPermissionServices";
import UseToggle from "../../../../../Hooks/UseToggle";

const PermissionTableData = ({
  permissionList,
  getPermissionsList,
  setViewAccessId,
  getAssignedPermission,
  setPermissionNameData,
  onMouseDown,
  tableRef,
}) => {
  const { toggle, setToggle } = UseToggle();
  const ref = useRef(null);
  const [id, setId] = useState("");
  const [permissionName, setPermissionName] = useState("");

  const handleStatusChange = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this Permission?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.status = status;
        let data = await designationPermissionServices.changePermissionStatus(
          userData
        );
        if (data?.statusCode === 200) {
          getPermissionsList();
        }
      }
    });
  };

  const handleUpdate = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this Permission?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.permission = permissionName;
        let data = await designationPermissionServices.updatePermissions(
          userData
        );
        if (data?.statusCode === 200) {
          getPermissionsList();
        }
      }
    });
  };

  const updatePermissionModal = (item) => {
    setPermissionName(item.permission);
    setId(item._id);
    setToggle();
  };

  const handleDelete = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Permission?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await designationPermissionServices.deletePermissions(id);
        if (data?.statusCode === 200) {
          getPermissionsList();
        }
      }
    });
  };
  return (
    <>
      <div className="table-container overflow-auto">
        <Table
          id="resizable-table"
          bordered
          className="custom-table m-0"
          ref={tableRef}
        >
          <thead>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Permission Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)} style={{ width: 120 }}>
                Status
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 3)} style={{ width: 130 }}>
                Updated Time
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 4)}
                style={{ width: 70 }}
                className="text-center"
              >
                View
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 5)}
                style={{ width: 70 }}
                className="text-center"
              >
                Upadate
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 6)}
                style={{ width: 70 }}
                className="text-center"
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {permissionList?.length > 0 &&
              permissionList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{item.permission}</td>
                  <td className="p-0">
                    <Form.Select
                      onChange={(e) =>
                        handleStatusChange(item._id, e.target.value)
                      }
                      className={`p-0 px-2 bg-transparent rounded-0 border-0 ${
                        item.status === "active"
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      <option
                        selected={item.status === "active"}
                        value="active"
                      >
                        Active
                      </option>
                      <option
                        selected={item.status === "inactive"}
                        value="inactive"
                      >
                        Inactive
                      </option>
                    </Form.Select>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.updatedAt}
                    </Moment>
                  </td>
                  <td className="text-center">
                    <button
                      onClick={() => {
                        setViewAccessId(item._id);
                        setPermissionNameData(item.permission);
                        getAssignedPermission(item._id);
                      }}
                      title="Update"
                      className="text-white border-primary bg-primary border-0 fs-10 br3"
                    >
                      View
                    </button>
                  </td>
                  <td className="text-center">
                    <button
                      onClick={() => updatePermissionModal(item)}
                      title="Update"
                      className="text-white border-primary bg-primary border-0 fs-10 br3"
                    >
                      Update
                    </button>
                  </td>
                  <td>
                    <div
                      className="text-center cursor-pointer"
                      title="Delete Sponser"
                      onClick={(e) => handleDelete(e, item._id)}
                    >
                      <i
                        className={`fa-solid fa-trash-can CP red text-danger`}
                      ></i>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <UpdatePermissionModal
        Show={!toggle}
        Hide={setToggle}
        Size={"md"}
        Title={"Update Permission"}
        permissionName={permissionName}
        setPermissionName={setPermissionName}
        handleUpdate={handleUpdate}
        Submit={"Update"}
      />
    </>
  );
};

export default PermissionTableData;

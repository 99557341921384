import config from "../config/config.json";
import {
    handleResponse,
    headersWithAuth,
    headersWithAuthWithoutBody,
} from "../APIHelpers/Responses";
import { headers } from "../APIHelpers/Headers";

export const studySettingControlServices = {
  addQuerySubject,
  getQuerySubjectList,
  updateQuerySubjectData,
  updateStatusQuerySubjectData,
  getActiveQuerySubjectList,
  getActiveQuerySubjectListByCategory,
  deleteQuerySubjectData,
  addQueryMessage,
  getQueryMessageList,
  updateQueryMessageData,
  updateStatusQueryMessageData,
  getActiveQueryMessageList,
  deleteQueryMessageData,
  addQueryResponseMessage,
  getQueryResponseMessageList,
  updateQueryResponseMessageData,
  updateStatusQueryResponseMessageData,
  getActiveQueryResponseMessageList,
  deleteQueryResponseMessageData,
};
//Add Query Subject
async function addQuerySubject(userData) {
  const response = await fetch(
    `${config.API_URL}addQuerySubject`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Update Query Subject
async function updateQuerySubjectData(userData) {
  const response = await fetch(
    `${config.API_URL}updateQuerySubjectData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Update Status Query Subject
async function updateStatusQuerySubjectData(userData) {
  const response = await fetch(
    `${config.API_URL}updateStatusQuerySubjectData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data)
  return data;
}

//Delete Query Subject
async function deleteQuerySubjectData(id) {
  const response = await fetch(
    `${config.API_URL}deleteQuerySubjectData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  return data;
}

//Get Query Subject
async function getQuerySubjectList() {
  const response = await fetch(
    `${config.API_URL}getQuerySubjectList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Get Active Query Subject
async function getActiveQuerySubjectList() {
  const response = await fetch(
    `${config.API_URL}getActiveQuerySubjectList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Get Active Query Subject
async function getActiveQuerySubjectListByCategory(category) {
  const response = await fetch(
    `${config.API_URL}getActiveQuerySubjectListByCategory/${category}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Add Query Message
async function addQueryMessage(userData) {
  const response = await fetch(
    `${config.API_URL}addQueryMessage`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Update Query Message
async function updateQueryMessageData(userData) {
  const response = await fetch(
    `${config.API_URL}updateQueryMessageData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Update Status Query Message
async function updateStatusQueryMessageData(userData) {
  const response = await fetch(
    `${config.API_URL}updateStatusQueryMessageData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Delete Query Message
async function deleteQueryMessageData(id) {
  const response = await fetch(
    `${config.API_URL}deleteQueryMessageData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  return data;
}

//Get Query Message
async function getQueryMessageList() {
  const response = await fetch(
    `${config.API_URL}getQueryMessageList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Get Active Query Message
async function getActiveQueryMessageList(subjectId) {
  const response = await fetch(
    `${config.API_URL}getActiveQueryMessageList/${subjectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Add Query Response Message
async function addQueryResponseMessage(userData) {
  const response = await fetch(
    `${config.API_URL}addQueryResponseMessage`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Update Query Response Message
async function updateQueryResponseMessageData(userData) {
  const response = await fetch(
    `${config.API_URL}updateQueryResponseMessageData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Update Status Query Response Message
async function updateStatusQueryResponseMessageData(userData) {
  const response = await fetch(
    `${config.API_URL}updateStatusQueryResponseMessageData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

//Delete Query Response Message
async function deleteQueryResponseMessageData(id) {
  const response = await fetch(
    `${config.API_URL}deleteQueryResponseMessageData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  return data;
}

//Get Query Response Message
async function getQueryResponseMessageList() {
  const response = await fetch(
    `${config.API_URL}getQueryResponseMessageList`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

//Get Active Query Response Message
async function getActiveQueryResponseMessageList(subjectId) {
  const response = await fetch(
    `${config.API_URL}getActiveQueryResponseMessageList/${subjectId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { LuDownload, LuImport } from "react-icons/lu";
import { supplimentaryServices } from "../../../../APIServices/Supplementary/sdtmServices";
import Swal from "sweetalert2";
import { FaPlusSquare } from "react-icons/fa";

const ImportLegacy = ({
  crfData,
  studyId,
  getStudyDataTemplates,
  crfDataId,
  setCRFDataId,
}) => {
  const [selectFormat, setSelectFormat] = useState("");
  const [fileDatasetName, setFileDatasetName] = useState("");
  const [fileDatasetLabel, setFileDatasetLabel] = useState("");
  const [dataTemplate, setDataTemplate] = useState([]);
  const [dataTemplateId, setDataTemplateId] = useState("");
  const [uploadFile, setUploadFile] = useState(null);

  const getActiveDataTemplate = async () => {
    let data = await supplimentaryServices.getActiveDataTemplate();
    setDataTemplate(data?.data);
  };

  const importDataTemplateinStudy = async () => {
    let userData = {};
    userData.studyId = studyId;
    userData.templateId = dataTemplateId;
    userData.datasetName = fileDatasetName;
    userData.datasetLabel = fileDatasetLabel;
    let data = await supplimentaryServices.importDataTemplateinStudy(userData);
    if (data?.statusCode === 200) {
      getStudyDataTemplates();
      setFileDatasetName("");
      setFileDatasetLabel("");
      setDataTemplateId("");
    }
  };

  const updateStudyDataTemplates = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this CRF Data?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = crfDataId._id;
        userData.datasetName = fileDatasetName;
        userData.datasetLabel = fileDatasetLabel;
        let data = await supplimentaryServices.updateStudyDataTemplates(
          userData
        );
        if (data?.statusCode === 200) {
          getStudyDataTemplates();
        }
      }
    });
  };

  useEffect(() => {
    setFileDatasetName(crfDataId?.datasetName);
    setFileDatasetLabel(crfDataId?.datasetLabel);
  }, [crfDataId]);

  const clearFormData = () => {
    setCRFDataId("");
    setDataTemplateId("");
    setFileDatasetName("");
    setFileDatasetLabel("");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const csvType = file.type === "text/csv";
    const excelType =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (
      (selectFormat === "csv" && csvType) ||
      (selectFormat === "excel" && excelType)
    )
      setUploadFile(file);
    else {
      setUploadFile(null);
      alert(`Please select a ${selectFormat.toUpperCase()} file.`);
    }
  };

  const handleUploadCRFFile = async () => {
    if (uploadFile) {
      const formData = new FormData();
      formData.append("file", uploadFile);
      formData.append("name", fileDatasetName);
      formData.append("label", fileDatasetLabel);
      formData.append("studyId", studyId);
      let result = await supplimentaryServices.uploadFileinCRFData(formData);
      if (result?.statusCode === 200) {
        clearFormData();
        getStudyDataTemplates();
      }
    }
  };

  const handleFormatChange = (format) => {
    setSelectFormat(format);
    setUploadFile(null);
  };

  useEffect(() => {
    getActiveDataTemplate();
  }, []);

  return (
    <>
      {!crfDataId && crfData === "custom" ? (
        <>
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">
              Format <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => handleFormatChange(e.target.value)}
              required
            >
              <option value="">Select Format</option>
              <option selected={selectFormat === "csv"} value="csv">
                CSV
              </option>
              <option selected={selectFormat === "excel"} value="excel">
                Excel
              </option>
            </Form.Select>
          </Form.Group>
          {selectFormat && (
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">
                Upload Template{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="file"
                onChange={handleFileChange}
                accept={selectFormat === "csv" ? ".csv" : ".xls, .xlsx"}
                required
              />
              <div className="text-end">
                <a href={"https://gas-admin-bucket.s3.ap-south-1.amazonaws.com/CRF+Template.csv"}>
                  <Form.Label
                    className="mb-0 fw-bold fs-10 cursor-pointer"
                    title="Download Template"
                  >
                    <LuDownload style={{ marginBottom: 2 }} /> Download CSV Template
                  </Form.Label>
                </a>
              </div>
            </Form.Group>
          )}
        </>
      ) : (
        <>
          {!crfDataId && (
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 fw-bold">
                Template <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setDataTemplateId(e.target.value)}
                required
              >
                <option value="">Select Template</option>
                {dataTemplate?.map((item, index) => (
                  <option
                    key={index}
                    value={item._id}
                    selected={item.dataTemplateId === item._id}
                  >
                    {item.templateName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
        </>
      )}
      <Form.Group className="mb-2">
        <div className="hstack justify-content-between">
          <Form.Label className="mb-1 fw-bold">
            Dataset Name <span className="text-danger">*</span>
          </Form.Label>
          {crfDataId && (
            <button
              className="fs10 br3 text-white border-primary bg-primary border-0"
              title="Add new Supplimentary"
              onClick={() => clearFormData()}
            >
              <FaPlusSquare style={{ marginBottom: 2 }} /> Add New
            </button>
          )}
        </div>
        <Form.Control
          type="text"
          placeholder="Enter File Dataset Name"
          onChange={(e) => {
            setFileDatasetName(e.target.value);
          }}
          value={fileDatasetName}
          required
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Dataset Label <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter File Dataset Label"
          onChange={(e) => {
            setFileDatasetLabel(e.target.value);
          }}
          value={fileDatasetLabel}
          required
        />
      </Form.Group>
      <Form.Group className="text-end">
        <button
          onClick={() =>
            crfDataId
              ? updateStudyDataTemplates()
              : crfData === "predefine"
                ? importDataTemplateinStudy()
                : handleUploadCRFFile()
          }
          className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
        >
          <LuImport style={{ marginBottom: 2 }} />{" "}
          {crfDataId ? "Update" : "Import"}
        </button>
      </Form.Group>
    </>
  );
};

export default ImportLegacy;

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";

const UpdateMethod = ({
  valueLaveldata,
  setValueLaveldata,
  getValueLevelData,
}) => {
  let [expressionContext, setExpressionContext] = useState("");
  let [expressionCode, setExpressionCode] = useState("");
  let [document, setDocument] = useState("");
  let [pages, setPages] = useState("");
  return (
    <>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Expression Context <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Expression Context"
          onChange={(e) => setExpressionContext(e.target.value)}
          value={expressionContext}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Expression Code <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Expression Code"
          onChange={(e) => setExpressionCode(e.target.value)}
          value={expressionCode}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Document <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Document"
          onChange={(e) => setDocument(e.target.value)}
          value={document}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Pages <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Pages"
          onChange={(e) => setPages(e.target.value)}
          value={pages}
        />
      </Form.Group>
      <Form.Group className="text-end">
        <button className="p-1 px-3 border rounded-1 text-white bg-color fs-13">
          <FaCheck style={{ marginBottom: 2 }} /> Update
        </button>
      </Form.Group>
    </>
  );
};

export default UpdateMethod;

import React, { useRef } from "react";
import { Form, Table } from "react-bootstrap";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { userServices } from "../../../../../APIServices/UserServices/usersServices";

const SubadminTableData = ({
  subadminList,
  getUserData,
  permissionList,
  designationList,
  onMouseDown,
  tableRef,
  setId,
  setIsFormOpen,
  setSubadminData,
}) => {
  const ref = useRef(null);
  const handleDelete = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Subadmin?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await userServices.deleteUserData(id);
        if (data?.statusCode === 200) {
          getUserData();
        }
      }
    });
  };

  const handleStatusChange = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this Subadmin?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.status = status;
        let data = await userServices.changeUserDataStatus(userData);
        if (data?.statusCode === 200) {
          getUserData();
        }
      }
    });
  };

  const handleDesignationChange = async (id, designationId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Designation this Subadmin?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.designationId = designationId;
        let data = await userServices.changeUserDesignation(userData);
        if (data?.statusCode === 200) {
          getUserData();
        }
      }
    });
  };

  const handlePermissionChange = async (id, permissionId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Permission this Subadmin?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.permissionId = permissionId;
        let data = await userServices.changeUserPermission(userData);
        if (data?.statusCode === 200) {
          getUserData();
        }
      }
    });
  };

  const updateDesignationModal = (item) => {
    console.log("item>>>>>", item);
    
    setIsFormOpen(true);
    setSubadminData(item);
    setId(item._id);
  };

  return (
    <>
      <div className="table-container overflow-auto">
        <Table
          id="resizable-table"
          bordered
          className="custom-table m-0"
          ref={tableRef}
        >
          <thead>
            <tr>
              <th
                onMouseDown={(e) => onMouseDown(e, 0)}
                style={{ width: "40px" }}
              >
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Subadmin Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Email Id</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Phone No.</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Gender</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Designation</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>Permission</th>
              <th onMouseDown={(e) => onMouseDown(e, 7)}>Status</th>
              <th onMouseDown={(e) => onMouseDown(e, 8)}>Registration</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 9)}
                style={{ width: 70 }}
                className="text-center"
              >
                Edit
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 10)}
                style={{ width: 70 }}
                className="text-center"
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {subadminList?.length > 0 &&
              subadminList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{`${item.firstName ? item.firstName : ""} ${
                    item.middleName ? item.middleName : ""
                  } ${item.lastName ? item.lastName : ""}`}</td>
                  <td>{item.email}</td>
                  <td>{item.phone}</td>
                  <td className="text-capitalize">{item.gender}</td>
                  <td className="p-0">
                    <Form.Select
                      className="p-0 px-2 bg-transparent border-0 rounded-0"
                      onChange={(e) =>
                        handleDesignationChange(item._id, e.target.value)
                      }
                    >
                      <option value="">No Designation</option>
                      {designationList?.map((data, index) => (
                        <option
                          selected={item.designationId === data._id}
                          key={index}
                          value={data._id}
                        >
                          {data.designation}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td className="p-0">
                    <Form.Select
                      className="p-0 px-2 bg-transparent border-0 rounded-0"
                      onChange={(e) =>
                        handlePermissionChange(item._id, e.target.value)
                      }
                    >
                      <option value="">No Permission</option>
                      {permissionList?.map((data, index) => (
                        <option
                          selected={item.permissionId === data._id}
                          key={index}
                          value={data._id}
                        >
                          {data.permission}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td className="p-0">
                    <Form.Select
                      onChange={(e) =>
                        handleStatusChange(item._id, e.target.value)
                      }
                      className={`className="p-0 px-2 bg-transparent border-0 rounded-0" ${
                        item.status === "active"
                          ? "text-success"
                          : item.status === "inactive"
                          ? "text-danger"
                          : "text-warning"
                      }`}
                    >
                      <option
                        selected={item.status === "active"}
                        value="active"
                      >
                        Active
                      </option>
                      <option
                        selected={item.status === "inactive"}
                        value="inactive"
                      >
                        Inactive
                      </option>
                      {item.status === "pending" && (
                        <option
                          selected={item.status === "pending"}
                          value="pending"
                        >
                          Pending
                        </option>
                      )}
                    </Form.Select>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.createdAt}
                    </Moment>
                  </td>
                  <td className="text-center">
                    <button
                      onClick={() => updateDesignationModal(item)}
                      title="Update"
                      className="text-white border-primary bg-primary border-0 fs-10 br3"
                    >
                      Update
                    </button>
                  </td>
                  <td>
                    <div
                      className="text-center cursor-pointer"
                      title="Delete Sponser"
                      onClick={(e) => handleDelete(e, item._id)}
                    >
                      <i
                        className={`fa-solid fa-trash-can CP red text-danger`}
                      ></i>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default SubadminTableData;

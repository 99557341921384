import React, { useEffect, useState } from "react";
import { Offcanvas, Table } from "react-bootstrap";
import { supplimentaryServices } from "../../../../APIServices/Supplementary/sdtmServices";

const TemplateColumnProperties = ({
  Show,
  Hide,
  Title,
  tableRef,
  onMouseDown,
  studyId,
  uniqueId
}) => {
  const [studyDataTemplateColumn, setStudyDataTemplateColumn] = useState([]);
  const getStudyDataTemplateEntryData = async (studyId, uniqueId) => {
    let data = await supplimentaryServices.getStudyDataTemplateEntryData(
      studyId,
      uniqueId
    );
    setStudyDataTemplateColumn(data?.columns || []);
  };
  useEffect(() => {
    getStudyDataTemplateEntryData(studyId, uniqueId);
  }, [studyId, uniqueId]);

  console.log(">>studyDataTemplateColumn", studyDataTemplateColumn);
  
  return (
    <>
      <Offcanvas show={Show} onHide={Hide} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>{Title} <span className="fs-10">-[Check for hide]</span></Offcanvas.Title>
          <div onClick={Hide} className="close_modal" title="Close">
            X
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="overflow-auto">
            <Table
              tableRef={tableRef}
              id="resizable-table"
              className="text-nowrap custom-table"
              hover
              bordered
              striped
            >
              <thead className="position-sticky z-1" style={{ top: -1 }}>
                <tr>
                  <th onMouseDown={(e) => onMouseDown(e, 1)} style={{ width: 40 }}>
                    Sr.
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 2)}>Name</th>
                  <th onMouseDown={(e) => onMouseDown(e, 3)}>Label</th>
                  <th onMouseDown={(e) => onMouseDown(e, 4)}>Type</th>
                  <th onMouseDown={(e) => onMouseDown(e, 5)}>
                    Permission
                  </th>
                </tr>
              </thead>
              <tbody>
                {studyDataTemplateColumn?.map((item, index) => (
                  <tr
                    key={index}
                    className={`position-relative cursor-pointer`}
                  >
                    <td>{index+1}</td>
                    <td title={item.name}>{item?.name}</td>
                    <td title={item.label}>{item?.label}</td>
                    <td title={item.type} className="text-capitalize">{item?.type}</td>
                    <td title={item.check} className="text-capitalize">{item?.check}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default TemplateColumnProperties;

import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Pagination from "../../../../Hooks/Pagination";
import { cdiscServices } from "../../../../APIServices/cdiscServices";
import Swal from "sweetalert2";

const VariableTableData = ({
  tableRef,
  onMouseDown,
  variableList,
  getCDISCVariablesList,
  setIsFormOpen,
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);

  const deleteCDISCVariables = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Variable!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let results = await cdiscServices.deleteCDISCVariables(id);
        if (results?.statusCode === 200) {
          getCDISCVariablesList();
        }
      }
    });
  };

  const handleUpdateClick = () => {
    setIsFormOpen(true);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = variableList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(variableList?.length / itemsPerPage);
  return (
    <>
      <div className="overflow-auto">
        <Table
          tableRef={tableRef}
          id="resizable-table"
          className="text-nowrap custom-table tableLibrary"
          hover
          bordered
          striped
        >
          <thead className="position-sticky z-1" style={{ top: -1 }}>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Order</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Dataset</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Variable</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Label</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Data Type</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>Length</th>
              <th onMouseDown={(e) => onMouseDown(e, 7)}>Significant</th>
              <th onMouseDown={(e) => onMouseDown(e, 8)}>Format</th>
              <th onMouseDown={(e) => onMouseDown(e, 9)}>Mandatory</th>
              <th onMouseDown={(e) => onMouseDown(e, 10)}>Codelist</th>
              <th onMouseDown={(e) => onMouseDown(e, 11)}>Origin</th>
              <th onMouseDown={(e) => onMouseDown(e, 12)}>Pages</th>
              <th onMouseDown={(e) => onMouseDown(e, 13)}>Method</th>
              <th onMouseDown={(e) => onMouseDown(e, 14)}>Predecessor</th>
              <th onMouseDown={(e) => onMouseDown(e, 15)}>Role</th>
              <th onMouseDown={(e) => onMouseDown(e, 16)}>Comment</th>
              <th onMouseDown={(e) => onMouseDown(e, 17)} style={{ width: 80 }}>
                <div className="text-center">Update</div>
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 18)}
                style={{ width: 70, right: -1 }}
                className="text-center position-sticky"
              >
                <div className="text-center">Delete</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {result?.map((item, index) => (
              <tr
                key={index}
                className={`position-relative cursor-pointer ${
                  highlightedRow === index && "activeMenu"
                }`}
                onClick={() => {
                  setHighlightedRow(index);
                }}
              >
                <td>{item.order}</td>
                <td>{item.domain}</td>
                <td>{item.name}</td>
                <td>{item.label}</td>
                <td className="text-capitalize">{item.type}</td>
                <td>{!item.type ? "" : item.type === "char" ? 200 : 8}</td>
                <td>{item.type === "float" ? item.significant : ""}</td>
                <td>{item.formate}</td>
                <td className="text-capitalize">{item.core}</td>
                <td>{item.codeListData}</td>
                <td>{item.origin}</td>
                <td>{item.page}</td>
                <td>{item.mathod}</td>
                <td>{item.predecessor}</td>
                <td className="text-capitalize">
                  {item.role.replace(/([A-Z])/g, " $1").trim()}
                </td>
                <td>{item.comment}</td>
                <td className="text-center">
                  <button
                    onClick={() => handleUpdateClick(item)}
                    title="Update"
                    className="text-white border-primary bg-primary border-0 fs-10 br3"
                  >
                    Update
                  </button>
                </td>
                <td className="position-sticky bg-light" style={{ right: -1 }}>
                  {item.core === "perm" ? (
                    <div
                      className="text-center cursor-pointer"
                      title="Delete Variable."
                      onClick={(e) => deleteCDISCVariables(e, item?._id)}
                    >
                      <i className="fa-solid fa-trash-can text-danger"></i>
                    </div>
                  ) : (
                    <div
                      className="text-center"
                      title="Can't Delete This Variable."
                    >
                      <i className="fa-solid fa-trash-can text-secondary"></i>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={variableList?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default VariableTableData;

import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { userServices } from "../../../../../APIServices/UserServices/usersServices";
import { addressServices } from "../../../../../APIServices/addressServices";

const AddNewSubadminModal = ({
  permissionList,
  designationList,
  getUserData,
}) => {
  let [firstName, setFirstName] = useState("");
  let [middleName, setMiddleName] = useState("");
  let [lastName, setLastName] = useState("");
  let [email, setEmail] = useState("");
  let [phoneNumber, setPhoneNumber] = useState("");
  let [gender, setGender] = useState("");
  let [address, setAddress] = useState("");
  let [countryId, setCountryId] = useState("");
  let [stateId, setStateId] = useState("");
  let [cityId, setCityId] = useState("");
  let [zipCode, setZipCode] = useState("");
  let [countryList, setCountryList] = useState([]);
  let [stateList, setStateList] = useState([]);
  let [cityList, setCityList] = useState([]);
  let [permissionId, setPermissionId] = useState("");
  let [designationId, setDesignationId] = useState("");
  let [emailError, setEmailError] = useState("");
  let [phoneError, setPhoneError] = useState("");

  const addNewSubadmin = async () => {
    let userData = {};
    userData.firstName = firstName;
    userData.middleName = middleName;
    userData.lastName = lastName;
    userData.email = email;
    userData.phone = phoneNumber;
    userData.gender = gender;
    userData.address = address;
    userData.countryId = countryId;
    userData.stateId = stateId;
    userData.cityId = cityId;
    userData.zipCode = zipCode;
    userData.permissionId = permissionId;
    userData.designationId = designationId;
    userData.roleType = "cdiscuser";
    let data = await userServices.addNewUser(userData);
    if (data?.statusCode === 200) {
      clearInputField();
      getUserData();
    }
  };

  const clearInputField = () => {
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setGender("");
    setAddress("");
    setCountryId("");
    setStateId("");
    setCityId("");
    setZipCode("");
    setPermissionId("");
    setDesignationId("");
    setEmailError("");
    setPhoneError("");
  };

  const getCountries = async () => {
    let data = await addressServices.getCountries();
    setCountryList(data?.data);
  };

  const getStates = async (id) => {
    let data = await addressServices.getStates(id);
    setStateList(data?.data);
  };

  const getCities = async (id) => {
    let data = await addressServices.getCities(id);
    setCityList(data?.data);
  };

  const validatEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      setEmailError("");
    } else {
      setEmailError("Invalide Email,Please Enter valid Email");
    }
  };

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;
    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number Should have 10 digit");
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          First Name <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          onChange={(e) => setFirstName(e.target.value)}
          value={firstName}
          placeholder="Enter First Name"
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">Middle Name</Form.Label>
        <Form.Control
          required
          type="text"
          onChange={(e) => setMiddleName(e.target.value)}
          value={middleName}
          placeholder="Enter Middle Name"
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Last Name <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
          placeholder="Enter Last Name"
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Email <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="email"
          placeholder="Enter Email ID"
          onChange={(e) => {
            setEmail(e.target.value);
            validatEmail(e.target.value);
          }}
          value={email}
        />
        {emailError && (
          <span className="message" style={{ color: "red", fontSize: 10 }}>
            {emailError}
          </span>
        )}
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Phone Number <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          onChange={(e) => {
            setPhoneNumber(e.target.value);
            validatePhoneNumber(e.target.value);
          }}
          value={phoneNumber}
          type="number"
          placeholder="Enter Phone Number"
        />
        {phoneError && (
          <span className="message" style={{ color: "red", fontSize: 10 }}>
            {phoneError}
          </span>
        )}
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Gender <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          onChange={(e) => setGender(e.target.value)}
          aria-label="Default select example"
        >
          <option>Select Gender</option>
          <option selected={gender === "male"} value="male">
            Male
          </option>
          <option selected={gender === "female"} value="female">
            Female
          </option>
          <option selected={gender === "other"} value="other">
            Other
          </option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Designation <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          aria-label="Default select example"
          onChange={(e) => setDesignationId(e.target.value)}
        >
          <option>Select Designation</option>
          {designationList?.map((item, index) => (
            <option
              selected={designationId === item._id}
              key={index}
              value={item._id}
            >
              {item.designation}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Permission <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          aria-label="Default select example"
          onChange={(e) => setPermissionId(e.target.value)}
        >
          <option>Select Permission</option>
          {permissionList?.map((item, index) => (
            <option
              selected={permissionId === item._id}
              key={index}
              value={item._id}
            >
              {item.permission}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Address <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          onChange={(e) => setAddress(e.target.value)}
          value={address}
          as="textarea"
          placeholder="Enter Address"
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Country <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          onChange={(e) => setCountryId(e.target.value)}
          onClick={(e) => getStates(e.target[e.target.selectedIndex].title)}
          aria-label="Default select example"
        >
          <option>Select Country</option>
          {countryList?.length > 0
            ? countryList?.map((item) => (
                <option
                  selected={item._id === countryId}
                  value={item._id}
                  title={item.id}
                >
                  {item.name}
                </option>
              ))
            : ""}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          State <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          onChange={(e) => setStateId(e.target.value)}
          onClick={(e) => getCities(e.target[e.target.selectedIndex].title)}
          aria-label="Default select example"
        >
          <option>Select State</option>
          {stateList?.length > 0
            ? stateList?.map((item) => (
                <option
                  selected={item._id === stateId}
                  value={item._id}
                  title={item.id}
                >
                  {item.name}
                </option>
              ))
            : ""}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          City <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select
          onChange={(e) => setCityId(e.target.value)}
          aria-label="Default select example"
        >
          <option>Select City</option>
          {cityList?.length > 0
            ? cityList?.map((item) => (
                <option selected={item._id === cityId} value={item._id}>
                  {item.name}
                </option>
              ))
            : ""}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Zip Code <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          onChange={(e) => setZipCode(e.target.value)}
          value={zipCode}
          required
          type="number"
          placeholder="Enter Zip Code"
        />
      </Form.Group>
      <div className="text-end">
        <button className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
          disabled={
            !firstName ||
            !lastName ||
            !email ||
            !phoneNumber ||
            !gender ||
            !address ||
            !countryId ||
            !stateId ||
            !cityId ||
            !zipCode ||
            !permissionId ||
            !designationId ||
            emailError ||
            phoneError
          }
          onClick={addNewSubadmin}
        >
          Add User
        </button>
      </div>
    </>
  );
};

export default AddNewSubadminModal;

import React, { useEffect, useState } from "react";
import AddNewSubadminModal from "./AddNewSubadminModal";
import SubadminTableData from "./SubadminTableData";
import { designationPermissionServices } from "../../../../../APIServices/UserServices/designationPermissionServices";
import { userServices } from "../../../../../APIServices/UserServices/usersServices";
import SplitPane from "react-split-pane";
import { BiCollapse, BiExpand } from "react-icons/bi";
import { TbLayoutSidebarRightExpand } from "react-icons/tb";
import { AiOutlinePlus } from "react-icons/ai";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { CiFilter } from "react-icons/ci";
import { VscClose } from "react-icons/vsc";
import UpdateSubadminModal from "./UpdateSubadminModal";

const Subadmins = ({ onMouseDown, tableRef }) => {
  const [id, setId] = useState("");
  const [subadminList, setSubadminList] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [designationList, setDesignationList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [subadminData, setSubadminData] = useState("");

  const getUserData = async () => {
    setLoader(true);
    let data = await userServices.getUserData("cdiscuser");
    setSubadminList(data?.data);
    setLoader(false);
  };

  const getPermissionsList = async () => {
    setLoader(true);
    let data = await designationPermissionServices.getActivePermissions();
    setPermissionList(data?.data);
    setLoader(false);
  };

  const getDesignationsList = async () => {
    setLoader(true);
    let data = await designationPermissionServices.getActiveDesignations();
    setLoader(false);
    setDesignationList(data?.data);
  };

  const handleSearch = async (searchValue) => {
    let userData = {};
    userData.searchData = searchValue || searchData;
    userData.roleType = "cdiscuser";
    let data = await userServices.searchUserData(userData);
    setSubadminList(data?.data);
  };

  useEffect(() => {
    getUserData();
    getPermissionsList();
    getDesignationsList();
  }, []);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const HeaderTools = [
    {
      title: isFormOpen ? "Hide Form" : "Add Sponser",
      icon: isFormOpen ? <TbLayoutSidebarRightExpand /> : <AiOutlinePlus />,
      onClick: () => setIsFormOpen(!isFormOpen),
    },
  ];
  return (
    <>
      <div
        className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}
      >
        <div
          className="hstack justify-content-between w-100 border-bottom"
          style={{ padding: "4.6px 9px" }}
        >
          <div className="hstack gap-2">
            {HeaderTools?.map((button, index) => (
              <React.Fragment key={index}>
                <button
                  title={button.title}
                  className="tabButtons border rounded-1 fs-13"
                  style={{ padding: "1px 5px" }}
                  onClick={button.onClick}
                >
                  {button.icon}
                </button>
              </React.Fragment>
            ))}
            <div className="vr"></div>
            <InputGroup>
              <InputGroup.Text className="p-0 border-0">
                <button
                  className="tabButtons border fs-13"
                  style={{ padding: "1px 5px", borderRadius: "3px 0 0 3px" }}
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  <CiFilter />
                </button>
              </InputGroup.Text>
              <div
                className={`p-0 d-flex gap-0 ${
                  isExpanded ? "expand-input" : "collapse-input"
                }`}
                style={{
                  maxWidth: isExpanded ? "200px" : "0px",
                  transition: "max-width 0.3s ease-out",
                  overflow: "hidden",
                }}
              >
                <Form.Control
                  className="p-0 rounded-0"
                  type="search"
                  onChange={() => handleSearch()}
                />
                <InputGroup.Text className="p-0 border-0">
                  <button
                    className="tabButtons border fs-13"
                    style={{ padding: "1px 5px", borderRadius: "0 3px 3px 0" }}
                  >
                    <VscClose />
                  </button>
                </InputGroup.Text>
              </div>
            </InputGroup>
          </div>
          <button
            className="tabButtons border rounded-1 fs-13"
            title={!isFullScreen ? "Full Mode" : "Original Mode"}
            onClick={toggleFullScreen}
          >
            {isFullScreen ? <BiCollapse /> : <BiExpand />}
          </button>
        </div>
        <div className="position-relative">
          <SplitPane
            split="horizontal"
            style={{
              height: isFullScreen
                ? "calc(100vh - 35px)"
                : "calc(100vh - 148px)",
            }}
            defaultSize={"100%"}
          >
            <SplitPane
              split="vertical"
              minSize={20}
              defaultSize={isFormOpen ? "20%" : "100%"}
            >
              {isFormOpen && (
                <>
                  {id ? (
                    <UpdateSubadminModal
                      subadminData={subadminData}
                      getUserData={getUserData}
                      setId={setId}
                      permissionList={permissionList}
                      designationList={designationList}
                    />
                  ) : (
                    <AddNewSubadminModal
                      getUserData={getUserData}
                      permissionList={permissionList}
                      designationList={designationList}
                    />
                  )}
                </>
              )}
              <>
                {loader ? (
                  <div className="hstack justify-content-center h-100">
                    <Spinner />
                  </div>
                ) : subadminList?.length === 0 ? (
                  <div className="hstack justify-content-center h-100">
                    Users Data not found!
                  </div>
                ) : (
                  <SubadminTableData
                    onMouseDown={onMouseDown}
                    tableRef={tableRef}
                    setId={setId}
                    setSubadminData={setSubadminData}
                    setIsFormOpen={setIsFormOpen}
                    subadminList={subadminList}
                    permissionList={permissionList}
                    designationList={designationList}
                    getUserData={getUserData}
                  />
                )}
              </>
            </SplitPane>
          </SplitPane>
        </div>
      </div>
    </>
  );
};

export default Subadmins;

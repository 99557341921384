import React, { useEffect, useState } from "react";
import { Alert, Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { addressServices } from "../../APIServices/addressServices";
import { invitedDataServices } from "../../APIServices/inviteAndRegisterServices";
import SignUpSuccess from "./SignUpSuccess";
import { NavLink } from "react-router-dom";
import { TfiBackLeft } from "react-icons/tfi";

const SignUp = () => {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [phone, setPhone] = useState("");
  const [cityLists, setCityLists] = useState([]);
  const [city_id, setCityID] = useState("");
  const [stateLists, setStateLists] = useState([]);
  const [state_id, setStateID] = useState("");
  const [countryLists, setCountryLists] = useState([]);
  const [country_id, setCountryID] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [statusCode, setStatusCode] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async (e) => {
    let data = await addressServices.getCountries(e);
    setCountryLists(data.data);
  };

  const getStates = async (e) => {
    let data = await addressServices.getStates(e);
    setStateLists(data.data);
  };

  const getCities = async (e) => {
    let data = await addressServices.getCities(e);
    setCityLists(data.data);
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let userData = {};
    userData.firstName = firstName;
    userData.middleName = middleName;
    userData.lastName = lastName;
    userData.address = address;
    userData.email = email;
    userData.gender = gender;
    userData.phone = phone;
    userData.cityId = city_id;
    userData.stateId = state_id;
    userData.countryId = country_id;
    userData.zipCode = postalCode;
    userData.roleType = "aiuser";
    let data = await invitedDataServices.userRegistration(userData);
    setStatusMessage(data);
    if (data?.statusCode === 200) {
      setLoader(false);
      setFirstName("");
      setMiddleName("");
      setLastName("");
      setGender("");
      setEmail("");
      setPhone("");
      setAddress("");
      setPostalCode("");
      setCityID("");
      setStateID("");
      setCountryID("");
      setStatusCode(true);
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern.test(email)) {
      setEmailError("");
    } else {
      setEmailError("Invalid Email, Please Enter Valid Email!");
    }
  };

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^\d{10}$/;

    if (phonePattern.test(phone)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone Number should have 10 digits!");
    }
  };
  return (
    <>
      <div
        className="w-100 hstack justify-content-center loginPageBgImage"
        style={{ height: "100vh" }}
      >
        <Container>
          <Row>
            <Form.Group as={Col} md={6}></Form.Group>
            <Form.Group as={Col} md={6} className="hstack justify-content-end">
              {statusCode ? (
                <SignUpSuccess />
              ) : (
                <Card
                  style={{ width: "30rem" }}
                  className="border rounded-1 shadow-sm p-3"
                >
                  <Card.Body>
                    <Card.Title className="fw-bold text-center mb-3">
                      SignUp
                    </Card.Title>
                    <Card.Text>
                      <Row>
                        <Form.Group as={Col} md="6" className="mb-2">
                          <Form.Label className="mb-1 fw-bold">
                            First Name <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter first name"
                            onChange={(e) => setFirstName(e.target.value)}
                            className={firstName && "border-success"}
                            value={firstName}
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-2">
                          <Form.Label className="mb-1 fw-bold">
                            Middle Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter middle name"
                            onChange={(e) => setMiddleName(e.target.value)}
                            className={middleName && "border-success"}
                            value={middleName}
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-2">
                          <Form.Label className="mb-1 fw-bold">
                            Last Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Last name"
                            className={lastName && "border-success"}
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-2">
                          <Form.Label className="mb-1 fw-bold">
                            Email <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter your email"
                            className={
                              (emailError && "border-danger text-danger") ||
                              (email && "border-success")
                            }
                            onChange={(e) => {
                              setEmail(e.target.value);
                              validateEmail(e.target.value);
                            }}
                            value={email}
                          />
                          {emailError && email && (
                            <Form.Text className="fs-10 m-0 text-danger">
                              {emailError}
                            </Form.Text>
                          )}
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-2">
                          <Form.Label className="mb-1 fw-bold">
                            Phone Number <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter Phone number"
                            className={
                              (phoneError && "border-danger text-danger") ||
                              (phone && "border-success")
                            }
                            onChange={(e) => {
                              setPhone(e.target.value);
                              validatePhoneNumber(e.target.value);
                            }}
                            value={phone}
                          />
                          {phoneError && phone && (
                            <Form.Text className="fs-10 m-0 text-danger">
                              {phoneError}
                            </Form.Text>
                          )}
                        </Form.Group>

                        <Form.Group as={Col} md="6" className="mb-2">
                          <Form.Label className="mb-1 fw-bold">
                            Gender
                          </Form.Label>
                          <Form.Select
                            onChange={(e) => setGender(e.target.value)}
                            aria-label="Default select example"
                            className={gender && "border-success"}
                          >
                            <option value="">Select Gender</option>
                            <option selected={gender === "male"} value="male">
                              Male
                            </option>
                            <option
                              selected={gender === "female"}
                              value="female"
                            >
                              Female
                            </option>
                            <option selected={gender === "other"} value="other">
                              Other
                            </option>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-2">
                          <Form.Label className="mb-1 fw-bold">
                            Address <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder="Enter your address"
                            className={address && "border-success"}
                            style={{ height: "29px" }}
                            onChange={(e) => setAddress(e.target.value)}
                            value={address}
                          />
                        </Form.Group>

                        <Form.Group as={Col} md="6" className="mb-2">
                          <Form.Label className="mb-1 fw-bold">
                            Country <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            onChange={(e) => setCountryID(e.target.value)}
                            className={country_id && "border-success"}
                            value={country_id}
                            onClick={(e) =>
                              getStates(e.target[e.target.selectedIndex].title)
                            }
                          >
                            <option>-Select-</option>
                            {countryLists.length > 0
                              ? countryLists.map((item, key) => (
                                  <option
                                    value={item._id}
                                    key={key}
                                    title={item.id}
                                  >
                                    {item.name}
                                  </option>
                                ))
                              : ""}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-2">
                          <Form.Label className="mb-1 fw-bold">
                            State <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            onChange={(e) => setStateID(e.target.value)}
                            className={state_id && "border-success"}
                            value={state_id}
                            onClick={(e) =>
                              getCities(e.target[e.target.selectedIndex].title)
                            }
                          >
                            <option>-Select-</option>
                            {stateLists.length > 0
                              ? stateLists.map((item, key) => (
                                  <option
                                    value={item._id}
                                    key={key}
                                    title={item.id}
                                  >
                                    {item.name}
                                  </option>
                                ))
                              : ""}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} md="6" className="mb-2">
                          <Form.Label className="mb-1 fw-bold">
                            City <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            onChange={(e) => setCityID(e.target.value)}
                            className={city_id && "border-success"}
                            value={city_id}
                          >
                            <option>-Select-</option>
                            {cityLists.length > 0
                              ? cityLists.map((item, key) => (
                                  <option value={item._id} key={key}>
                                    {item.name}
                                  </option>
                                ))
                              : ""}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} md="6" className="mb-2">
                          <Form.Label className="mb-1 fw-bold">
                            Zip Code <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            required
                            type="number"
                            placeholder="Enter Postel Code"
                            className={postalCode && "border-success"}
                            onChange={(e) => setPostalCode(e.target.value)}
                            value={postalCode}
                          />
                        </Form.Group>
                        <Form.Group className="mb-2">
                          <button
                            className={`w-100 hstack gap-1 justify-content-center tabButtons border rounded-1 fs-13 p-1`}
                            disabled={
                              loader ||
                              !firstName ||
                              !address ||
                              !phone ||
                              !email ||
                              !city_id ||
                              !state_id ||
                              !country_id ||
                              !postalCode ||
                              emailError ||
                              phoneError
                            }
                            onClick={handleSubmit}
                          >
                            <div className="fw-bold">
                              {loader && (
                                <Spinner style={{ width: 14, height: 14 }} />
                              )}{" "}
                              SignUp
                            </div>
                          </button>
                        </Form.Group>
                        <Form.Group>
                          <div className="text-end">
                            <NavLink to={"/login"} className="fs-13 text-dark">
                              Already have Account? Login <TfiBackLeft />
                            </NavLink>
                          </div>
                        </Form.Group>
                        {statusMessage && (
                          <Form.Group className="my-2">
                            <Alert
                              className="fs-10 py-1 px-1 m-0"
                              variant={
                                statusMessage?.statusCode === 200
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {statusMessage?.customMessage}
                            </Alert>
                          </Form.Group>
                        )}
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
              )}
            </Form.Group>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SignUp;

import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { CiFilter, CiViewTable } from "react-icons/ci";
import SplitPane from "react-split-pane";
import { BiCollapse, BiExpand } from "react-icons/bi";
import UseToggle from "../../../../Hooks/UseToggle";
import ValueLabelTableData from "./ValueLabelTableData";
import ValueLabelTableProperties from "./ValueLabelTableProperties";
import { cdiscServices } from "../../../../APIServices/cdiscServices";
import ValueLabelFilter from "./ValueLabelFilter";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import UpdateValueLavel from "./UpdateValueLavel";
import { TbLayoutSidebarRightExpand } from "react-icons/tb";

const ValueLabel = ({ onMouseDown, tableRef }) => {
  const [loader, setLoader] = useState(false);
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [filter, setFilter] = useState(false);
  const [valueLaveldata, setValueLaveldata] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const currentUrl = window.location.href;
  const splitUrl = currentUrl?.split("/");
  const studyId = splitUrl[4];

  const getValueLevelData = async () => {
    setLoader(true);
    let data = await cdiscServices.getValueLevelData(studyId);
    setValueLaveldata(data?.data);
    setLoader(false);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const HeaderTools = [
    {
      title: "View Table Properties",
      icon: <CiViewTable />,
      onClick: setToggle,
    },
    {
      title: "Filter",
      icon: !filter ? <MdFilterAlt /> : <MdFilterAltOff />,
      onClick: () => {
        setToggle1();
        setFilter(false);
      },
    },
  ];

  useEffect(() => {
    getValueLevelData();
  }, []);

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div
        className="hstack justify-content-between w-100 border-bottom"
        style={{ padding: "4.6px 9px" }}
      >
        <div className="hstack gap-2">
          {isFormOpen && (
            <>
              <button
                title="Hide Form"
                className="tabButtons border rounded-1 fs-13"
                style={{ padding: "1px 5px" }}
                onClick={() => setIsFormOpen(!isFormOpen)}
              >
                <TbLayoutSidebarRightExpand />
              </button>
              <div className="vr"></div>
            </>
          )}
          {HeaderTools?.map((button, index) => (
            <React.Fragment key={index}>
              <button
                title={button.title}
                className="tabButtons border rounded-1 fs-13"
                style={{ padding: "1px 5px" }}
                onClick={button.onClick}
              >
                {button.icon}
              </button>
              {index < 1 && <div className="vr"></div>}
            </React.Fragment>
          ))}
        </div>
        <button
          className="tabButtons border rounded-1 fs-13"
          title={!isFullScreen ? "Full Mode" : "Original Mode"}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <BiCollapse /> : <BiExpand />}
        </button>
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize={"100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={isFormOpen ? "20%" : "100%"}
          >
            {isFormOpen && (
              <UpdateValueLavel
                valueLaveldata={valueLaveldata}
                getValueLevelData={getValueLevelData}
                setValueLaveldata={setValueLaveldata}
              />
            )}
            <>
              {loader ? (
                <div className="hstack justify-content-center h-100 w-100">
                  <Spinner />
                </div>
              ) : valueLaveldata?.length === 0 ? (
                <div className="hstack justify-content-center h-100 w-100">
                  Data not found!
                </div>
              ) : (
                <ValueLabelTableData
                  tableRef={tableRef}
                  onMouseDown={onMouseDown}
                  valueLaveldata={valueLaveldata}
                  setIsFormOpen={setIsFormOpen}
                  getValueLevelData={getValueLevelData}
                />
              )}
            </>
          </SplitPane>
        </SplitPane>
      </div>
      <ValueLabelTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
      <ValueLabelFilter
        Show={!toggle1}
        Hide={setToggle1}
        valueLaveldata={valueLaveldata}
        setValueLaveldata={setValueLaveldata}
        studyId={studyId}
        getValueLevelData={getValueLevelData}
        Title={"Filter Value Label"}
        filter={filter}
        setFilter={setFilter}
      />
    </div>
  );
};

export default ValueLabel;

import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { authServices } from "../../APIServices/authServices";
import { IoLogOutOutline } from "react-icons/io5";

const OtpVerification = ({ authType, currentUserData }) => {
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const otpInputs = useRef([]);
  const [loader, setLoader] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const handleInputChange = (index, e) => {
    const value = e.target.value;
    if (value === "" || /^[0-9]$/.test(value)) {
      const updatedValues = [...otpValues];
      updatedValues[index] = value;
      setOtpValues(updatedValues);
      if (value !== "" && index < 5) {
        otpInputs.current[index + 1].focus();
      }
      if (value === "" && index > 0) {
        otpInputs.current[index - 1].focus();
      }
    }
  };

  const twpFactorAuthenticationVerify = async () => {
    setLoader(true);
    let endPoint =
      authType === "apps"
        ? authServices.verifyTwoFAForAppLogin
        : authServices.verifyTwoFAForEmailLogin;
    let userData = {};
    userData.otp = otpValues;
    let data = await endPoint(userData);
    setLoader(false);
    setStatusMessage(data);
    if (data?.statusCode === 200) {
      setOtpValues(["", "", "", "", "", ""]);
      currentUserData();
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const logout = async () => {
    await authServices.logout();
  };

  const [timer, setTimer] = useState(120);
  const [display, setDisplay] = useState("02:00");

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timer > 0) {
        const minutes = Math.floor(timer / 60);
        const seconds = timer % 60;
        const displayValue = `${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`;
        setDisplay(displayValue);
        setTimer((timer) => timer - 1);
      } else {
        setDisplay("00:00");
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer]);

  const sendOTPForEmailTwoFa = async () => {
    await authServices.emailOTPFor2FA();
  };
  return (
    <>
      <div
        className="w-100 hstack justify-content-center loginPageBgImage"
        style={{ height: "100vh" }}
      >
        <Container>
          <Row>
            <Form.Group as={Col} md={6}></Form.Group>
            <Form.Group as={Col} md={6} className="hstack justify-content-end">
              <Card
                style={{ width: "30rem" }}
                className="border rounded-1 shadow-sm p-3"
              >
                <Card.Body>
                  <Card.Title className="fw-bold text-center">
                    Otp Verification
                  </Card.Title>
                  <Card.Text>
                    <Form.Group className="mb-2">
                      <div className="otp-input-fields hstack gap-2 py-3 justify-content-center">
                        {otpValues.map((value, index) => (
                          <Form.Control
                            key={index}
                            type="text"
                            pattern="\d*"
                            maxLength="1"
                            className={`border text-center otp__digit otp__field__${
                              index + 1
                            }`}
                            style={{ height: 35, width: 35 }}
                            value={value}
                            onChange={(e) => handleInputChange(index, e)}
                            ref={(input) => (otpInputs.current[index] = input)}
                          />
                        ))}
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <button
                        className={`w-100 hstack gap-1 justify-content-center tabButtons border rounded-1 fs-13 p-1`}
                        onClick={twpFactorAuthenticationVerify}
                        type="submit"
                      >
                        {loader && (
                          <Spinner style={{ width: 14, height: 14 }} />
                        )}{" "}
                        Verify
                      </button>
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <div
                        className={`w-100 hstack mb-2 ${
                          authType === "email"
                            ? "justify-content-between"
                            : "justify-content-end"
                        }`}
                      >
                        {authType === "email" && (
                          <div title="Clich here for generate new OTP">
                            {display === "00:00" ? (
                              <a
                                className="text-dark fs-13 cursor-pointer"
                                onClick={sendOTPForEmailTwoFa}
                              >
                                Resend OTP
                              </a>
                            ) : (
                              <Form.Label className="m-0">{display}</Form.Label>
                            )}
                          </div>
                        )}
                        <a
                          className="text-dark fs-13 cursor-pointer"
                          onClick={logout}
                        >
                          Logout <IoLogOutOutline />
                        </a>
                      </div>
                      {statusMessage && (
                        <Alert
                          className="fs-10 py-1 px-1 m-0 text-center"
                          variant={
                            statusMessage?.statusCode === 200
                              ? "success"
                              : "danger"
                          }
                        >
                          {statusMessage?.customMessage}
                        </Alert>
                      )}
                    </Form.Group>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Form.Group>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default OtpVerification;

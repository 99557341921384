import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";

const UpdateValueLavel = ({
  valueLaveldata,
  setValueLaveldata,
  getValueLevelData,
}) => {
  let [formate, setFormate] = useState("");
  let [pages, setPages] = useState("");
  let [method, setMethod] = useState("");
  let [predecessor, setPredecessor] = useState("");
  let [joinComment, setJoinComment] = useState("");
  return (
    <>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Format <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Formate"
          onChange={(e) => setFormate(e.target.value)}
          value={formate}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Pages <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Pages"
          onChange={(e) => setPages(e.target.value)}
          value={pages}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Method <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Method"
          onChange={(e) => setMethod(e.target.value)}
          value={method}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Predecessor <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Predecessor"
          onChange={(e) => setPredecessor(e.target.value)}
          value={predecessor}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Join Comment	 <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Join Comment	"
          onChange={(e) => setJoinComment	(e.target.value)}
          value={joinComment	}
        />
      </Form.Group>
      <Form.Group className="text-end">
        <button className="p-1 px-3 border rounded-1 text-white bg-color fs-13">
          <FaCheck style={{ marginBottom: 2 }} /> Update
        </button>
      </Form.Group>
    </>
  );
};

export default UpdateValueLavel;

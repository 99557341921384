import React, { useEffect, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { FaCheck, FaXmark } from "react-icons/fa6";
import { cdiscServices } from "../../../../APIServices/cdiscServices";

const StudySupplementaryFilter = ({
    Show,
    Hide,
    Title,
    addNewStudySupplementarydata,
    setaddNewStudySupplementarydata,
    studyId,
    getSupplimentalsList,
    setFilter,
    filter
}) => {
    const [classData, setClassData] = useState("");
    const [classList, setClassList] = useState([]);
    const [domain, setDomain] = useState("");
    const [domainList, setDomainList] = useState([]);
    const [mathod, setMathod] = useState("");
    const [mathodList, setMathodList] = useState([]);
    const [comment, setComment] = useState("");
    const [commentList, setCommentList] = useState([]);
    const [list, setList] = useState("");
    const [codeList, setCodeList] = useState([]);
    const [type, setType] = useState("");
    const [dataTypeList, setDataTypeList] = useState([]);
    const [mandatory, setMandatory] = useState("");
    const [mandatoryList, setMandatoryList] = useState([]);
    const [origin, setOrigin] = useState("");
    const [originList, setOriginList] = useState([]);

    const filterStudySupplimentalsList = async () => {
        let userData = {};
        userData.studyId = studyId;
        userData.domainId = domain;
        userData.mathodId = mathod;
        userData.commentId = comment;
        userData.codeList = list;
        userData.type = type;
        userData.mandatory = mandatory;
        userData.origin = origin;
        let data = await cdiscServices.filterStudySupplimentalsList(userData);
        setaddNewStudySupplementarydata(data?.data || []);
        setFilter(true)
        Hide();
    };

    const clearFilterData = () => {
        setDomain("");
        setClassData("");
        setMathod("");
        setComment("");
        setList("");
        setType("");
        setMandatory("");
        setOrigin("");
        Hide();
        getSupplimentalsList();
        setFilter(false)
    };

    useEffect(() => {
        if (!filter) {
            clearFilterData()
        }
    }, [filter])

    useEffect(() => {
        setClassList(
            Array.from(
                new Map(
                    addNewStudySupplementarydata?.map((item) => [
                        item.class + "_" + item.classId,
                        { class: item.class, classId: item.classId },
                    ])
                ).values()
            )
        );
        setDomainList(
            Array.from(
                new Map(
                    addNewStudySupplementarydata?.map((item) => [
                        item.domain + "_" + item.domainId,
                        { domain: item.domain, domainId: item.domainId },
                    ])
                ).values()
            )
        );
        setMathodList(
            Array.from(
                new Map(
                    addNewStudySupplementarydata
                        ?.filter((item) => item.mathodId !== "")
                        .map((item) => [
                            item.mathod + "_" + item.mathodId,
                            { mathod: item.mathod, mathodId: item.mathodId },
                        ])
                ).values()
            )
        );
        setCommentList(
            Array.from(
                new Map(
                    addNewStudySupplementarydata
                        ?.map((item) => [
                            item.comment + "_" + item.commentId,
                            { comment: item.comment, commentId: item.commentId },
                        ])
                        .filter((item) => item?.commentId !== "") || []
                ).values()
            )
        );
        setCodeList(
            Array.from(
                new Map(
                    addNewStudySupplementarydata
                        ?.map((item) => [
                            item.codeListData + "_" + item.codeList,
                            { codeListData: item.codeListData, codeList: item.codeList },
                        ])
                        .filter((item) => item?.codeListData !== "") || []
                ).values()
            )
        );
        setDataTypeList(
            Array.from(new Set(addNewStudySupplementarydata?.map((item) => item.dataType) || []))
        );
        setMandatoryList(
            Array.from(new Set(addNewStudySupplementarydata?.map((item) => item.mandatory) || []))
        );
        setOriginList(
            Array.from(new Set(addNewStudySupplementarydata?.map((item) => item.origin) || []))
        );
    }, [addNewStudySupplementarydata]);

    return (
        <>
            <Offcanvas show={Show} onHide={Hide} placement="end">
                <Offcanvas.Header>
                    <Offcanvas.Title>{Title} </Offcanvas.Title>
                    <div onClick={Hide} className="close_modal" title="Close">
                        X
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0">
                    <div
                        className="pt-2 px-3 overflow-auto"
                        style={{ height: "calc(100vh - 84px)" }}
                    >
                        <Form.Group className="mb-2">
                            <Form.Label className="mb-1 fw-bold">Class Id</Form.Label>
                            <Form.Select onChange={(e) => setClassData(e.target.value)}>
                                <option value="">Select Class Id</option>
                                {classList?.map((item, index) => (
                                    <option
                                        selected={item.classId === classData}
                                        key={index}
                                        value={item.classId}
                                    >
                                        {item.class}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label className="mb-1 fw-bold">Domain Id</Form.Label>
                            <Form.Select onChange={(e) => setDomain(e.target.value)}>
                                <option value="">Select Domain Id</option>
                                {domainList?.map((item, index) => (
                                    <option
                                        selected={item.domainId === domain}
                                        key={index}
                                        value={item.domainId}
                                    >
                                        {item.domain}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label className="mb-1 fw-bold">Data Type</Form.Label>
                            <Form.Select onChange={(e) => setType(e.target.value)}>
                                <option value="">Select Data Type</option>
                                {dataTypeList?.map((item, index) => (
                                    <option selected={item === type} key={index} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label className="mb-1 fw-bold">Mandatory</Form.Label>
                            <Form.Select onChange={(e) => setMandatory(e.target.value)}>
                                <option value="">Select Mandatory</option>
                                {mandatoryList?.map((item, index) => (
                                    <option
                                        selected={item === mandatory}
                                        key={index}
                                        value={item}
                                    >
                                        {item}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label className="mb-1 fw-bold">Origin</Form.Label>
                            <Form.Select onChange={(e) => setOrigin(e.target.value)}>
                                <option value="">Select Origin</option>
                                {originList?.map((item, index) => (
                                    <option selected={item === origin} key={index} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        {mathodList?.length > 0 && (
                            <Form.Group className="mb-2">
                                <Form.Label className="mb-1 fw-bold">Mathod</Form.Label>
                                <Form.Select onChange={(e) => setMathod(e.target.value)}>
                                    <option value="">Select Mathod</option>
                                    {mathodList?.map((item, index) => (
                                        !item.mathodId ? "" :
                                            <option
                                                selected={item.mathodId === mathod}
                                                key={index}
                                                value={item.mathodId}
                                            >
                                                {item.mathod}
                                            </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        )}
                        {commentList?.length > 0 && (
                            <Form.Group className="mb-2">
                                <Form.Label className="mb-1 fw-bold">Comment</Form.Label>
                                <Form.Select onChange={(e) => setComment(e.target.value)}>
                                    <option value="">Select Comment</option>
                                    {commentList?.map((item, index) => (
                                        !item.commentId ? "" :
                                            <option
                                                selected={item.commentId === comment}
                                                key={index}
                                                value={item.commentId}
                                            >
                                                {item.comment}
                                            </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        )}
                        {codeList?.length > 0 && (
                            <Form.Group className="mb-2">
                                <Form.Label className="mb-1 fw-bold">Code List</Form.Label>
                                <Form.Select onChange={(e) => setList(e.target.value)}>
                                    <option value="">Select Code List</option>
                                    {codeList?.map((item, index) => (
                                        !item.codeList ? "" :
                                            <option
                                                selected={item.codeList === list}
                                                key={index}
                                                value={item.codeList}
                                            >
                                                {item.codeListData}
                                            </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        )}
                    </div>
                    <div className="text-end py-2 px-3 shadow-sm bg-light w-100">
                        {filter &&
                            <button
                                className="py-1 px-3 border rounded-1 text-white bg-danger me-2 fs-13"
                                onClick={() => clearFilterData()}
                            >
                                <FaXmark /> Clear
                            </button>
                        }
                        <button
                            className="py-1 px-3 border rounded-1 text-white bg-color fs-13"
                            onClick={() => filterStudySupplimentalsList()}
                        >
                            <FaCheck /> Apply
                        </button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default StudySupplementaryFilter;

import React from "react";
import { Form } from "react-bootstrap";
import { designationPermissionServices } from "../../../../../APIServices/UserServices/designationPermissionServices";
import Swal from "sweetalert2";

const UpdateDesignationModal = ({
  id,
  designation,
  setId,
  setDesignation,
  getDesignationsList,
}) => {
  const handleUpdate = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Designation?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.designation = designation;
        let data = await designationPermissionServices.updateDesignations(
          userData
        );
        if (data?.statusCode === 200) {
          getDesignationsList();
        }
      }
    });
  };
  const clearForm = () => {
    setId("");
    setDesignation("");
  };
  return (
    <>
      <Form.Group className="mb-2">
        <div className="hstack justify-content-between">
          <Form.Label className="mb-1 fw-bold">
            Designation <span className="text-damnger">*</span>
          </Form.Label>
          <button
            className="text-white border-primary bg-primary border-0 fs-10 br3"
            onClick={clearForm}
          >
            Add New
          </button>
        </div>
        <Form.Control
          required
          type="text"
          value={designation}
          onChange={(e) => setDesignation(e.target.value)}
          placeholder="Enter Designation"
        />
      </Form.Group>

      <div className="text-end">
        <button
          className="p-1 px-3 border rounded-1 text-white bg-color fs-13"
          disabled={!designation}
          onClick={handleUpdate}
        >
          Update Designation
        </button>
      </div>
    </>
  );
};

export default UpdateDesignationModal;

import React, { useRef } from "react";
import { Form, Table } from "react-bootstrap";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { designationPermissionServices } from "../../../../../APIServices/UserServices/designationPermissionServices";

const DesignationTableData = ({
  designationList,
  getDesignationsList,
  onMouseDown,
  tableRef,
  setId,
  setDesignation,
  setIsFormOpen,
}) => {
  const ref = useRef(null);
  const handleStatusChange = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Change Status this Designation?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = id;
        userData.status = status;
        let data = await designationPermissionServices.changeDesignationsStatus(
          userData
        );
        if (data?.statusCode === 200) {
          getDesignationsList();
        }
      }
    });
  };

  const updateDesignationModal = (item) => {
    setIsFormOpen(true);
    setDesignation(item.designation);
    setId(item._id);
  };

  const handleDelete = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Designation?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await designationPermissionServices.deleteDesignations(id);
        if (data?.statusCode === 200) {
          getDesignationsList();
        }
      }
    });
  };

  return (
    <>
      <div className="overflow-auto">
        <Table
          id="resizable-table"
          bordered
          className="custom-table m-0"
          ref={tableRef}
        >
          <thead>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Designation Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)} style={{ width: 120 }}>
                Status
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 3)} style={{ width: 130 }}>
                Updated Time
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 5)}
                style={{ width: 70 }}
                className="text-center"
              >
                Edit
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 6)}
                style={{ width: 70 }}
                className="text-center"
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {designationList?.length > 0 &&
              designationList?.map((item, index) => (
                <tr className="relative">
                  <td>{index + 1}</td>
                  <td>{item.designation}</td>
                  <td className="p-0">
                    <Form.Select
                      onChange={(e) =>
                        handleStatusChange(item._id, e.target.value)
                      }
                      className={`p-0 px-2 rounded-0 border-0 ${
                        item.status === "active"
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      <option
                        selected={item.status === "active"}
                        value="active"
                      >
                        Active
                      </option>
                      <option
                        selected={item.status === "inactive"}
                        value="inactive"
                      >
                        Inactive
                      </option>
                    </Form.Select>
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {item.updatedAt}
                    </Moment>
                  </td>
                  <td className="text-center">
                    <button
                      onClick={() => updateDesignationModal(item)}
                      title="Update"
                      className="text-white border-primary bg-primary border-0 fs-10 br3"
                    >
                      Update
                    </button>
                  </td>
                  <td>
                    <div
                      className="text-center cursor-pointer"
                      title="Delete Sponser"
                      onClick={(e) => handleDelete(e, item._id)}
                    >
                      <i
                        className={`fa-solid fa-trash-can CP red text-danger`}
                      ></i>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default DesignationTableData;

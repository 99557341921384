import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";

const UpdateVariable = () => {
  let [significant, setSignificant] = useState("");
  let [format, setFormat] = useState("");
  let [pages, setPages] = useState("");
  let [predecessor, setPredecessor] = useState("");
  return (
    <>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Significant <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Significant"
          onChange={(e) => setSignificant(e.target.value)}
          value={significant}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Format <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Format"
          onChange={(e) => setFormat(e.target.value)}
          value={format}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Pages <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Pages"
          onChange={(e) => setPages(e.target.value)}
          value={pages}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="mb-1 fw-bold">
          Predecessor <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Predecessor"
          onChange={(e) => setPredecessor(e.target.value)}
          value={predecessor}
        />
      </Form.Group>
      <Form.Group className="text-end">
        <button className="p-1 px-3 border rounded-1 text-white bg-color fs-13">
          <FaCheck style={{ marginBottom: 2 }} /> Update
        </button>
      </Form.Group>
    </>
  );
};

export default UpdateVariable;

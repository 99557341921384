import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Col, Container, Form, Row } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import { LuImport } from "react-icons/lu";
import { supplimentaryServices } from "../../../../APIServices/Supplementary/sdtmServices";

const AddValueLabel = ({
  getClientValueLevelData,
  valueLabelData,
  setValueLabelData,
}) => {
  let [formateType, setFormateType] = useState("");
  let [version, setVersion] = useState([]);
  let [versionId, setVersionId] = useState("");
  let [sdtmClass, setSDTMClass] = useState([]);
  let [order, setOrder] = useState("");
  let [classId, setClassId] = useState("");
  let [domain, setDomain] = useState([]);
  let [domainId, setDomainId] = useState("");
  let [variableMethod, setVariableMethod] = useState([]);
  let [whereclause, setWhereclause] = useState([]);
  let [datasetName, setDatasetName] = useState([]);
  let [valueLevelVeriableName, setValueLevelVeriableName] = useState("");
  let [whereclauseVeriableName, setWhereclauseVeriableName] = useState("");
  let [whereclauseLabel, setWhereclauseLabel] = useState("");
  let [valueLevelWhereclause, setValueLevelWhereclause] = useState("");
  let [variableType, setVariableType] = useState("");
  let [significant, setSignificant] = useState("");
  let [mandatory, setMandatory] = useState("");
  let [variableComment, setVariableComment] = useState([]);
  let [commentId, setCommentId] = useState("");
  let [codeListData, setCodeListData] = useState([]);
  let [methodId, setMethodId] = useState("");
  let [origin, setOrigin] = useState("");
  let [codeList, setCodeList] = useState("");

  const addNewClientValueLevel = async () => {
    let userData = {};
    userData.formateType = formateType;
    userData.domainId = domainId;
    if (formateType === "custom") {
      userData.order = order;
      userData.mathodId = methodId;
      userData.commentId = commentId;
      userData.origin = origin;
      userData.mandatory = mandatory;
      userData.significant = significant;
      userData.dataType = variableType;
      userData.whereclauseLabel = whereclauseLabel;
      userData.whereclauseVeriableName = whereclauseVeriableName;
      userData.valueLevelWhereclause = valueLevelWhereclause;
      userData.valueLevelVeriableName = valueLevelVeriableName;
      userData.codeList = codeList;
    }
    let data = await supplimentaryServices.addNewClientValueLevel(userData);
    if (data?.statusCode === 200) {
      getClientValueLevelData();
    }
  };

  const updateClientValueLevelData = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this value label?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let userData = {};
        userData.id = valueLabelData._id;
        userData.mathodId = methodId;
        userData.commentId = commentId;
        userData.origin = origin;
        userData.order = order;
        userData.mandatory = mandatory;
        userData.significant = significant;
        userData.dataType = variableType;
        userData.codeList = codeList;
        userData.whereclauseLabel = whereclauseLabel;
        userData.valueLevelWhereclause = valueLevelWhereclause;
        let data = await supplimentaryServices.updateClientValueLevelData(
          userData
        );
        if (data?.statusCode === 200) {
          getClientValueLevelData();
          setValueLabelData("");
        }
      }
    });
  };

  useEffect(() => {
    setFormateType(valueLabelData?.formateType);
    setOrder(valueLabelData?.order);
    setMethodId(valueLabelData?.mathodId);
    setCommentId(valueLabelData?.commentId);
    setOrigin(valueLabelData?.origin);
    setMandatory(valueLabelData?.mandatory);
    setSignificant(valueLabelData?.significant);
    setVariableType(valueLabelData?.dataType);
    setCodeList(valueLabelData?.codeList);
    setWhereclauseLabel(valueLabelData?.whereclauseLabel);
    setValueLevelWhereclause(valueLabelData?.valueLevelWhereclause);
  }, [valueLabelData]);

  const getActiveVersions = async () => {
    let data = await supplimentaryServices.getActiveVersions();
    setVersion(data?.data);
  };

  const getActiveClass = async (versionId) => {
    let data = await supplimentaryServices.getActiveClass(versionId);
    setSDTMClass(data?.data);
  };

  const getActiveDomains = async (classId) => {
    let data = await supplimentaryServices.getActiveDomains(classId);
    setDomain(data?.data);
  };

  const getActiveVariableComments = async () => {
    let data = await supplimentaryServices.getActiveVariableComments();
    setVariableComment(data?.data);
  };

  const getActiveVariableMathods = async () => {
    let data = await supplimentaryServices.getActiveVariableMathods();
    setVariableMethod(data?.data);
  };

  const getValueLevelVariablesList = async (domainId) => {
    let data = await supplimentaryServices.getValueLevelVariablesList(domainId);
    setDatasetName(data?.data || []);
  };

  const getWithoutValueLevelVariablesList = async (domainId) => {
    let data = await supplimentaryServices.getWithoutValueLevelVariablesList(
      domainId
    );
    setWhereclause(data?.data || []);
  };

  const getSTDMCodeList = async () => {
    let data = await supplimentaryServices.getSTDMCodeList();
    setCodeListData(data?.data);
  };

  useEffect(() => {
    getActiveVersions();
    getActiveClass();
    getActiveDomains();
    getActiveVariableMathods();
    getActiveVariableComments();
    getSTDMCodeList();
  }, []);

  const clearFormData = () => {
    setValueLabelData("");
    setClassId("");
    setVersionId("");
    setDomainId("");
    setOrder("");
    setVariableType("");
    setSignificant("");
    setMandatory("");
    setCommentId("");
    setMethodId("");
    setOrigin("");
    setCodeList("");
  };

  return (
    <Container fluid>
      {!valueLabelData ? (
        <Row>
          <Form.Group as={Col} md="12" className="mb-2 p-0">
            <Form.Label className="fs-12 fw-bold mb-1">
              Type <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              style={{ fontSize: 11 }}
              onChange={(e) => setFormateType(e.target.value)}
            >
              <option value="">Select Type</option>
              <option
                selected={formateType === "standard" && "standard"}
                value="standard"
              >
                Standard
              </option>
              <option
                selected={formateType === "custom" && "custom"}
                value="custom"
              >
                Custom
              </option>
            </Form.Select>
          </Form.Group>
          {formateType === "standard" && (
            <>
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Version <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  style={{ fontSize: 11 }}
                  onChange={(e) => {
                    setVersionId(e.target.value);
                    getActiveClass(e.target.value);
                  }}
                  value={versionId}
                >
                  <option value="">Select Version</option>
                  {version?.map((item, index) => (
                    <option
                      selected={item.versionId === item._id}
                      key={index}
                      value={item._id}
                    >
                      {item.version}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Class <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  style={{ fontSize: 11 }}
                  onChange={(e) => {
                    setClassId(e.target.value);
                    getActiveDomains(e.target.value);
                  }}
                  value={classId}
                >
                  <option value="">Select Class</option>
                  {sdtmClass?.map((item, index) => (
                    <option
                      selected={item.classId === item._id}
                      key={index}
                      value={item._id}
                    >
                      {item.class}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Domain <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  style={{ fontSize: 11 }}
                  onChange={(e) => {
                    setDomainId(e.target.value);
                    getValueLevelVariablesList(e.target.value);
                    getWithoutValueLevelVariablesList(e.target.value);
                  }}
                  value={domainId}
                >
                  <option value="">Select Domain</option>
                  {domain?.map((item, index) => (
                    <option
                      selected={item.domainId === item._id}
                      key={index}
                      value={item._id}
                    >
                      {item.domain}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="text-end p-0">
                <button
                  className="p-1 px-3 border rounded-1 text-white bg-color fs-13 text-capitalize"
                  onClick={() => addNewClientValueLevel()}
                >
                  <LuImport style={{ marginBottom: 2 }} /> Import Value Label
                </button>
              </Form.Group>
            </>
          )}
          {formateType === "custom" && (
            <>
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Order <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="number"
                  value={order}
                  style={{ fontSize: 11 }}
                  onChange={(e) => setOrder(e.target.value)}
                  placeholder="Enter Order No."
                />
              </Form.Group>
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Version <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  style={{ fontSize: 11 }}
                  onChange={(e) => {
                    setVersionId(e.target.value);
                    getActiveClass(e.target.value);
                  }}
                  value={versionId}
                >
                  <option value="">Select Version</option>
                  {version?.map((item, index) => (
                    <option
                      selected={item.versionId === item._id}
                      key={index}
                      value={item._id}
                    >
                      {item.version}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Class <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  style={{ fontSize: 11 }}
                  onChange={(e) => {
                    setClassId(e.target.value);
                    getActiveDomains(e.target.value);
                  }}
                  value={classId}
                >
                  <option value="">Select Class</option>
                  {sdtmClass?.map((item, index) => (
                    <option
                      selected={item.classId === item._id}
                      key={index}
                      value={item._id}
                    >
                      {item.class}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Domain <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  style={{ fontSize: 11 }}
                  onChange={(e) => {
                    setDomainId(e.target.value);
                    getValueLevelVariablesList(e.target.value);
                    getWithoutValueLevelVariablesList(e.target.value);
                  }}
                  value={domainId}
                >
                  <option value="">Select Domain</option>
                  {domain?.map((item, index) => (
                    <option
                      selected={item.domainId === item._id}
                      key={index}
                      value={item._id}
                    >
                      {item.domain}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Value Level Variable Name{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setValueLevelVeriableName(e.target.value)}
                  value={valueLevelVeriableName}
                >
                  <option value="">Select Value Level Variable Name</option>
                  {datasetName?.map((item, index) => (
                    <option
                      key={index}
                      value={item._id}
                      selected={item.dataSetId === item._id}
                    >
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Whereclause Variable Name{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setWhereclauseVeriableName(e.target.value)}
                  value={whereclauseVeriableName}
                >
                  <option value="">Select Whereclause Variable Name</option>
                  {whereclause?.map((item, index) => (
                    <option
                      key={index}
                      value={item._id}
                      selected={item.dataSetId === item._id}
                    >
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Value Level Whereclause <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={valueLevelWhereclause}
                  style={{ fontSize: 11 }}
                  onChange={(e) => setValueLevelWhereclause(e.target.value)}
                  placeholder="Enter Value Level Whereclause"
                />
              </Form.Group>
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Whereclause Label <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={whereclauseLabel}
                  style={{ fontSize: 11 }}
                  onChange={(e) => setWhereclauseLabel(e.target.value)}
                  placeholder="EnterWhereclause Label"
                />
              </Form.Group>
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Data Type <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  style={{ fontSize: 11 }}
                  onChange={(e) => setVariableType(e.target.value)}
                >
                  <option value="">Select Type</option>
                  <option
                    selected={variableType === "text" && "text"}
                    value="text"
                  >
                    Text
                  </option>
                  <option
                    selected={variableType === "integer" && "integer"}
                    value="integer"
                  >
                    Integer
                  </option>
                  <option
                    selected={variableType === "float" && "float"}
                    value="float"
                  >
                    Float
                  </option>
                </Form.Select>
              </Form.Group>
              {variableType === "float" && (
                <Form.Group as={Col} md="12" className="mb-2 p-0">
                  <Form.Label className="fs-12 fw-bold mb-1">
                    Significant
                  </Form.Label>
                  <Form.Control
                    required
                    type="number"
                    value={significant}
                    style={{ fontSize: 11 }}
                    onChange={(e) => setSignificant(e.target.value)}
                    placeholder="Enter Veriable Name"
                  />
                </Form.Group>
              )}
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Mandatory <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  style={{ fontSize: 11 }}
                  onChange={(e) => setMandatory(e.target.value)}
                >
                  <option value="">Select Type</option>
                  <option selected={mandatory === "yes" && "yes"} value="yes">
                    Yes
                  </option>
                  <option selected={mandatory === "no" && "no"} value="no">
                    No
                  </option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Origin <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  style={{ fontSize: 11 }}
                  onChange={(e) => setOrigin(e.target.value)}
                >
                  <option value="">Select Origin</option>
                  <option
                    selected={origin === "Protocol" && "Protocol"}
                    value="Protocol"
                  >
                    Protocol
                  </option>
                  <option
                    selected={origin === "Assigned" && "Assigned"}
                    value="Assigned"
                  >
                    Assigned
                  </option>
                  <option
                    selected={origin === "Derived" && "Derived"}
                    value="Derived"
                  >
                    Derived
                  </option>
                  <option selected={origin === "CRF" && "CRF"} value="CRF">
                    CRF
                  </option>
                  <option selected={origin === "eDT" && "eDT"} value="eDT">
                    eDT
                  </option>
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Variable Comment
                </Form.Label>
                <Form.Select
                  style={{ fontSize: 11 }}
                  onChange={(e) => {
                    setCommentId(e.target.value);
                  }}
                  value={commentId}
                  disabled={methodId}
                >
                  <option value="">Select Variable Comment</option>
                  {variableComment?.map((item, index) => (
                    <option
                      selected={item.commentId === item._id}
                      key={index}
                      value={item._id}
                    >
                      {item.commentId}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Variable Method
                </Form.Label>
                <Form.Select
                  style={{ fontSize: 11 }}
                  onChange={(e) => setMethodId(e.target.value)}
                  value={methodId}
                  disabled={commentId}
                >
                  <option value="">Select Variable Method</option>
                  {variableMethod?.map((item, index) => (
                    <option
                      selected={item.mathodId === item._id}
                      key={index}
                      value={item._id}
                    >
                      {item.mathodId}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="12" className="mb-2 p-0">
                <Form.Label className="fs-12 fw-bold mb-1">
                  Code List <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  style={{ fontSize: 11 }}
                  onChange={(e) => setCodeList(e.target.value)}
                  value={codeList}
                >
                  <option value="">Select Code List</option>
                  {codeListData?.map((item, index) => (
                    <option key={index} value={item?.codeList}>
                      {item?.codeId}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="text-end p-0">
                <button
                  className="p-1 px-3 border rounded-1 text-white bg-color fs-13 text-capitalize"
                  onClick={() => addNewClientValueLevel()}
                >
                  <FaPlusSquare style={{ marginBottom: 2 }} /> Add Value Label
                </button>
              </Form.Group>
            </>
          )}
        </Row>
      ) : (
        <Row>
          <Form.Group as={Col} md="12" className="mb-2 p-0">
            <div className="hstack justify-content-between">
              <Form.Label className="fs-12 fw-bold mb-1">
                Order <span className="text-danger">*</span>
              </Form.Label>
              <button
                className="fs10 br3 text-white border-primary bg-primary border-0"
                title="Add new Method"
                onClick={() => clearFormData()}
              >
                <FaPlusSquare style={{ marginBottom: 2 }} /> Add New
              </button>
            </div>
            <Form.Control
              required
              type="number"
              value={order}
              style={{ fontSize: 11 }}
              onChange={(e) => setOrder(e.target.value)}
              placeholder="Enter Order No."
            />
          </Form.Group>

          <Form.Group as={Col} md="12" className="mb-2 p-0">
            <Form.Label className="fs-12 fw-bold mb-1">
              Value Level Whereclause <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              value={valueLevelWhereclause}
              style={{ fontSize: 11 }}
              onChange={(e) => setValueLevelWhereclause(e.target.value)}
              placeholder="Enter Value Level Whereclause"
            />
          </Form.Group>
          <Form.Group as={Col} md="12" className="mb-2 p-0">
            <Form.Label className="fs-12 fw-bold mb-1">
              Whereclause Label <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              value={whereclauseLabel}
              style={{ fontSize: 11 }}
              onChange={(e) => setWhereclauseLabel(e.target.value)}
              placeholder="EnterWhereclause Label"
            />
          </Form.Group>
          <Form.Group as={Col} md="12" className="mb-2 p-0">
            <Form.Label className="fs-12 fw-bold mb-1">
              Data Type <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              style={{ fontSize: 11 }}
              onChange={(e) => setVariableType(e.target.value)}
            >
              <option value="">Select Type</option>
              <option selected={variableType === "text" && "text"} value="text">
                Text
              </option>
              <option
                selected={variableType === "integer" && "integer"}
                value="integer"
              >
                Integer
              </option>
              <option
                selected={variableType === "float" && "float"}
                value="float"
              >
                Float
              </option>
            </Form.Select>
          </Form.Group>
          {variableType === "float" && (
            <Form.Group as={Col} md="12" className="mb-2 p-0">
              <Form.Label className="fs-12 fw-bold mb-1">
                Significant
              </Form.Label>
              <Form.Control
                required
                type="number"
                value={significant}
                style={{ fontSize: 11 }}
                onChange={(e) => setSignificant(e.target.value)}
                placeholder="Enter Veriable Name"
              />
            </Form.Group>
          )}
          <Form.Group as={Col} md="12" className="mb-2 p-0">
            <Form.Label className="fs-12 fw-bold mb-1">
              Mandatory <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              style={{ fontSize: 11 }}
              onChange={(e) => setMandatory(e.target.value)}
            >
              <option value="">Select Type</option>
              <option selected={mandatory === "yes" && "yes"} value="yes">
                Yes
              </option>
              <option selected={mandatory === "no" && "no"} value="no">
                No
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="12" className="mb-2 p-0">
            <Form.Label className="fs-12 fw-bold mb-1">
              Origin <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              style={{ fontSize: 11 }}
              onChange={(e) => setOrigin(e.target.value)}
            >
              <option value="">Select Origin</option>
              <option
                selected={origin === "Protocol" && "Protocol"}
                value="Protocol"
              >
                Protocol
              </option>
              <option
                selected={origin === "Assigned" && "Assigned"}
                value="Assigned"
              >
                Assigned
              </option>
              <option
                selected={origin === "Derived" && "Derived"}
                value="Derived"
              >
                Derived
              </option>
              <option selected={origin === "CRF" && "CRF"} value="CRF">
                CRF
              </option>
              <option selected={origin === "eDT" && "eDT"} value="eDT">
                eDT
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="12" className="mb-2 p-0">
            <Form.Label className="fs-12 fw-bold mb-1">
              Variable Comment
            </Form.Label>
            <Form.Select
              style={{ fontSize: 11 }}
              onChange={(e) => {
                setCommentId(e.target.value);
              }}
              value={commentId}
              disabled={methodId}
            >
              <option value="">Select Variable Comment</option>
              {variableComment?.map((item, index) => (
                <option
                  selected={item.commentId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.commentId}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="12" className="mb-2 p-0">
            <Form.Label className="fs-12 fw-bold mb-1">
              Variable Method
            </Form.Label>
            <Form.Select
              style={{ fontSize: 11 }}
              onChange={(e) => setMethodId(e.target.value)}
              value={methodId}
              disabled={commentId}
            >
              <option value="">Select Variable Method</option>
              {variableMethod?.map((item, index) => (
                <option
                  selected={item.mathodId === item._id}
                  key={index}
                  value={item._id}
                >
                  {item.mathodId}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} md="12" className="mb-2 p-0">
            <Form.Label className="fs-12 fw-bold mb-1">
              Code List <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              style={{ fontSize: 11 }}
              onChange={(e) => setCodeList(e.target.value)}
              value={codeList}
            >
              <option value="">Select Code List</option>
              {codeListData?.map((item, index) => (
                <option key={index} value={item?.codeList}>
                  {item?.codeId}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="text-end p-0">
            <button
              className="p-1 px-3 border rounded-1 text-white bg-color fs-13 text-capitalize"
              onClick={() => updateClientValueLevelData()}
            >
              <FaPlusSquare style={{ marginBottom: 2 }} /> Update Value Label
            </button>
          </Form.Group>
        </Row>
      )}
    </Container>
  );
};

export default AddValueLabel;

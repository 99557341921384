import React, { useState } from "react";
import {
  Alert,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { authServices } from "../../APIServices/authServices";
import { TfiBackLeft } from "react-icons/tfi";

const ForgetPassword = ({ currentUserData }) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword((prevState) => !prevState);
  };

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const changePassword = async () => {
    setLoader(true);
    let userData = {};
    userData.currentPassword = currentPassword;
    userData.newPassword = newPassword;
    userData.repeatPassword = repeatPassword;
    let data = await authServices.changePassword(userData);
    setLoader(false);
    setStatusMessage(data);
    if (data?.statusCode === 200) {
      resetInputField();
      currentUserData();
    }
    setTimeout(() => {
      setStatusMessage("");
    }, 3000);
  };

  const [error, setError] = useState("");

  const validatePassword = (input) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/;

    if (!passwordRegex.test(input)) {
      setError(
        "Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long."
      );
    } else {
      setError("");
    }
  };

  const logout = async () => {
    await authServices.logout();
  };

  const resetInputField = () => {
    setCurrentPassword("");
    setNewPassword("");
    setRepeatPassword("");
  };
  return (
    <>
      <div
        className="w-100 hstack justify-content-center loginPageBgImage"
        style={{ height: "100vh" }}
      >
        <Container>
          <Row>
            <Form.Group as={Col} md={6}></Form.Group>
            <Form.Group as={Col} md={6} className="hstack justify-content-end">
              <Card
                style={{ width: "30rem" }}
                className="border rounded-1 shadow-sm p-3"
              >
                <Card.Body>
                  <Card.Title className="fw-bold text-center">
                    Change Password
                  </Card.Title>
                  <Card.Text>
                    <Form.Group className="mb-2">
                      <Form.Label className="mb-1 fw-bold">
                        Current Password <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          required
                          type={showCurrentPassword ? "text" : "password"}
                          placeholder="Enter Old Password"
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          value={currentPassword}
                        />
                        <div
                          className="passwordvisiability"
                          onClick={toggleCurrentPasswordVisibility}
                        >
                          {showCurrentPassword ? (
                            <i className="fa-regular fa-eye-slash fs-13"></i>
                          ) : (
                            <i className="fa-regular fa-eye fs-13"></i>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label className="mb-1 fw-bold">
                        New Password <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          required
                          type={showPassword ? "text" : "password"}
                          className={
                            (error && "border-danger text-danger") ||
                            (newPassword && "border-success")
                          }
                          placeholder="Enter New Password"
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                            validatePassword(e.target.value);
                          }}
                          value={newPassword}
                        />
                        {newPassword && (
                          <>
                            {error ? (
                              <Form.Text className="fs-10 text-danger fw-bold">
                                Password Must have 1 Capital Letter, 1 Small
                                Letter, 1 Special Character with 8 Character
                              </Form.Text>
                            ) : (
                              <Form.Text className="fs-10 text-success fw-bold">
                                Strong Password
                              </Form.Text>
                            )}
                          </>
                        )}
                        <div
                          className="passwordvisiability"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <i className="fa-regular fa-eye-slash fs-13"></i>
                          ) : (
                            <i className="fa-regular fa-eye fs-13"></i>
                          )}
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 fw-bold">
                        Confirm Password <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          required
                          type={showConfirmPassword ? "text" : "password"}
                          className={
                            (newPassword !== repeatPassword &&
                              "border-danger text-danger") ||
                            (repeatPassword && "border-success")
                          }
                          placeholder="Enter Password"
                          onChange={(e) => setRepeatPassword(e.target.value)}
                          value={repeatPassword}
                        />
                        <div
                          className="passwordvisiability"
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          {showConfirmPassword ? (
                            <i className="fa-regular fa-eye-slash fs-13"></i>
                          ) : (
                            <i className="fa-regular fa-eye fs-13"></i>
                          )}
                        </div>
                        {repeatPassword && (
                          <>
                            {newPassword === repeatPassword ? (
                              <Form.Text className="fs-10 text-success fw-bold">
                                Password Match
                              </Form.Text>
                            ) : (
                              <Form.Text className="fs-10 text-danger fw-bold">
                                Password Not Match
                              </Form.Text>
                            )}
                          </>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <div className="hstack gap-2 justify-content-end m-0">
                        <button
                          onClick={resetInputField}
                          className="w-100 hstack gap-1 justify-content-center tabButtons border rounded-1 fs-13 p-1"
                        >
                          Reset
                        </button>
                        <button
                          className="w-100 hstack gap-1 justify-content-center tabButtons border rounded-1 fs-13 p-1"
                          disabled={
                            error ||
                            !currentPassword ||
                            !newPassword ||
                            !repeatPassword
                          }
                          onClick={changePassword}
                        >
                          {loader && (
                            <Spinner style={{ width: 14, height: 14 }} />
                          )}{" "}
                          Change Password
                        </button>
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-2 text-end">
                      <a
                        onClick={logout}
                        className="fs-13 text-dark cursor-pointer"
                        title="Click for Login panel"
                      >
                        Back to Login <TfiBackLeft />
                      </a>
                    </Form.Group>
                    {statusMessage && (
                      <Alert
                        className="fs-10 py-1 px-1 m-0 text-center"
                        variant={
                          statusMessage?.statusCode === 200
                            ? "success"
                            : "danger"
                        }
                      >
                        {statusMessage?.customMessage}
                      </Alert>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Form.Group>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ForgetPassword;

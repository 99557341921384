import React, { useEffect, useState } from "react";
import { Alert, Form, Spinner, Table } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { FaSquarePlus } from "react-icons/fa6";
import SplitPane from "react-split-pane";
import { studySettingControlServices } from "../../../../APIServices/studySettingControlServices";
import Pagination from "../../../../Hooks/Pagination";

const StudyQuerySubjectControl = ({ tableRef, onMouseDown }) => {
  const [id, setId] = useState("");
  const [querySubject, setQuerySubject] = useState("");
  const [querySubjectId, setQuerySubjectId] = useState("");
  const [querySubjectList, setQuerySubjectList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [tableloader, setTableLoader] = useState(false);
  const [statusCode, setStatusCode] = useState(0);
  const [customMessage, setCustomMessage] = useState("");

  setTimeout(() => {
    setStatusCode(0);
    setCustomMessage("");
  }, 5000);

  const getQuerySubjectList = async () => {
    setTableLoader(true);
    let data = await studySettingControlServices.getQuerySubjectList();
    setQuerySubjectList(data?.data);
    setTableLoader(false);
  };

  const deleteQuerySubjectData = async (e, id) => {
    e.preventDefault();
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Query Subject!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    });
    if (result.isConfirmed) {
      setTableLoader(true);
      await studySettingControlServices.deleteQuerySubjectData(id);
      getQuerySubjectList();
      setTableLoader(false);
    }
  };

  const addQuerySubject = async (e) => {
    let data = {};
    setLoader(true);
    setTableLoader(true);
    data.subject = querySubject;
    let result = await studySettingControlServices.addQuerySubject(data);
    if (result?.statusCode === 200) {
      clearForm();
      setLoader(false);
      setTableLoader(false);
    } else {
      setLoader(false);
      setTableLoader(false);
    }
    setStatusCode(result?.statusCode);
    setCustomMessage(result.customMessage);
  };

  const updateQuerySubjectData = async (e) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to Update this Query Subject!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    });
    if (result.isConfirmed) {
      let data = {};
      setTableLoader(true);
      setLoader(true);
      data.id = id;
      data.subject = querySubject;
      let result = await studySettingControlServices.updateQuerySubjectData(
        data
      );
      if (result?.statusCode === 200) {
        clearForm();
        setLoader(false);
        setTableLoader(false);
      } else {
        setLoader(false);
        setTableLoader(false);
      }
      setStatusCode(result?.statusCode);
      setCustomMessage(result.customMessage);
    }
  };

  const updateStatusQuerySubjectData = async (id, status) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to Update Status this Query Subject!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    });
    if (result.isConfirmed) {
      let data = {};
      setTableLoader(true);
      data.id = id;
      data.status = status;
      let result =
        await studySettingControlServices.updateStatusQuerySubjectData(data);
      if (result?.statusCode === 200) {
        clearForm();
        setTableLoader(false);
      } else {
        setTableLoader(false);
      }
    }
  };

  const updateQuerySubjectDataModal = (e, id, querySubject) => {
    setId(id);
    setQuerySubject(querySubject);
  };

  const clearForm = () => {
    getQuerySubjectList();
    setQuerySubject("");
    setQuerySubjectId("");
  };

  const handleClick = (e, id, querySubject) => {
    updateQuerySubjectDataModal(e, id, querySubject);
    setQuerySubjectId(id);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = querySubjectList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(querySubjectList?.length / itemsPerPage);

  useEffect(() => {
    getQuerySubjectList();
  }, []);
  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize={"25%"}
        style={{ height: "calc(100vh - 115px)" }}
      >
        <div className="p-2">
          <Form.Group className="mb-2">
            <div className="hstack justify-content-between">
              <Form.Label className="fw-bold mb-1">
                Query Subject <span className="text-danger">*</span>
              </Form.Label>
              {querySubjectId && (
                <button
                  className="mb-1 bg-dark-subtle rounded-1 bg-opacity-10 border"
                  style={{ fontSize: 12 }}
                  onClick={() => clearForm()}
                  title="Add Query Subject"
                >
                  <FaSquarePlus className="me-2" style={{ marginTop: -2 }} />
                  Add Query Subject
                </button>
              )}
            </div>
            <Form.Control
              className="mb-2"
              required
              type="text"
              onChange={(e) => setQuerySubject(e.target.value)}
              value={querySubject}
              placeholder="Enter Query Subject"
            />
          </Form.Group>

          <div className="text-end mb-2">
            {!querySubjectId && (
              <button
                onClick={(e) => addQuerySubject(e)}
                className="p-1 px-3 border rounded-1 text-white bg-color fs-13 text-capitalize"
                disabled={loader || !querySubject}
              >
                {loader ? (
                  <Spinner
                    className="me-1"
                    style={{ width: 15, height: 15 }}
                    animation="border"
                  />
                ) : (
                  <i className="fa-solid fa-square-plus me-1"></i>
                )}
                Add Query Subject
              </button>
            )}
            {querySubjectId && (
              <button
                onClick={(e) => updateQuerySubjectData(e)}
                className="p-1 px-3 border rounded-1 text-white bg-color fs-13 text-capitalize"
                disabled={loader}
              >
                {loader ? (
                  <Spinner
                    className="me-1"
                    style={{ width: 15, height: 15 }}
                    animation="border"
                  />
                ) : (
                  <i className="fa-solid fa-pen-to-square me-1"></i>
                )}
                Update Query Subject
              </button>
            )}
          </div>
          {customMessage && (
            <Alert
              className="p-0 px-2 fs-13 br3"
              variant={statusCode === 200 ? "success" : "danger"}
            >
              {customMessage}
            </Alert>
          )}
        </div>
        <>
          {tableloader ? (
            <div className="hstack justify-content-center h-100">
              <Spinner />
            </div>
          ) : result?.length === 0 || result?.length === undefined ? (
            <div className="hstack justify-content-center h-100">
              Query Subject Message not found!
            </div>
          ) : (
            <>
              <Table
                id="resizable-table"
                striped
                bordered
                className="m-0 custom-table"
                ref={tableRef}
                hover
              >
                <thead className="thead-sticky">
                  <tr>
                    <th
                      style={{ width: 55 }}
                      onMouseDown={(e) => onMouseDown(e, 0)}
                    >
                      S No.
                    </th>
                    <th onMouseDown={(e) => onMouseDown(e, 1)}>
                      Query Subject
                    </th>
                    <th onMouseDown={(e) => onMouseDown(e, 2)}>Status</th>
                    <th onMouseDown={(e) => onMouseDown(e, 3)}>
                      Created Date | Time
                    </th>
                    <th onMouseDown={(e) => onMouseDown(e, 4)}>
                      Updated Date | Time
                    </th>
                    <th
                      className="text-center"
                      onMouseDown={(e) => onMouseDown(e, 5)}
                      style={{ width: 70 }}
                    >
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {result?.map((item, index) => (
                    <tr
                      key={index}
                      className="CP"
                      style={{
                        background:
                          querySubjectId === item._id ? "#ccd6dd " : "",
                      }}
                    >
                      <td
                        onClick={(e) => handleClick(e, item._id, item.subject)}
                      >
                        {startIndex + index + 1}
                      </td>
                      <td
                        onClick={(e) => handleClick(e, item._id, item.subject)}
                      >
                        {item.subject}
                      </td>
                      <td className="p-0">
                        <Form.Select
                          onChange={(e) =>
                            updateStatusQuerySubjectData(
                              item._id,
                              e.target.value
                            )
                          }
                          style={{ backgroundColor: "transparent" }}
                          className="p-1 border-0 rounded-0"
                        >
                          <option>Select Status</option>
                          <option
                            selected={item.status === "active"}
                            value={"active"}
                          >
                            Active
                          </option>
                          <option
                            selected={item.status === "inactive"}
                            value={"inactive"}
                          >
                            Inactive
                          </option>
                        </Form.Select>
                      </td>
                      <td
                        onClick={(e) => handleClick(e, item._id, item.subject)}
                      >
                        <Moment format="DD MMM YYYY | HH:mm" withTitle>
                          {item.createdAt}
                        </Moment>
                      </td>
                      <td
                        onClick={(e) => handleClick(e, item._id, item.subject)}
                      >
                        <Moment format="DD MMM YYYY | HH:mm" withTitle>
                          {item.updatedAt}
                        </Moment>
                      </td>
                      <td
                        onClick={(e) => deleteQuerySubjectData(e, item._id)}
                        className="text-center"
                      >
                        <FaTrashAlt className="delete text-danger " />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="position-sticky" style={{ bottom: 0.4 }}>
                <Pagination
                  totalPage={totalPage}
                  pageNumber={pageNumber}
                  itemsPerPage={itemsPerPage}
                  totalItems={querySubjectList?.length}
                  setPageNumber={setPageNumber}
                  setItemsPerPage={setItemsPerPage}
                />
              </div>
            </>
          )}
        </>
      </SplitPane>
    </>
  );
};

export default StudyQuerySubjectControl;

import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import Moment from "react-moment";
import { cdiscServices } from "../../../../APIServices/cdiscServices";

const ReviewHistory = ({ onMouseDown, tableRef, crfProperties }) => {
  const [reviewHistory, setReviewHistory] = useState([]);
  const [loader, setLoader] = useState(false);
  const getStudyDataTemplateReviewHistory = async (id) => {
    setLoader(true);
    let data = await cdiscServices.getStudyDataTemplateReviewHistory(id);
    setReviewHistory(data?.data || []);
    setLoader(false);
  };
  useEffect(() => {
    getStudyDataTemplateReviewHistory(crfProperties?._id);
  }, [crfProperties]);
  return (
    <>
      {loader ? (
        <div className="hstack justify-content-center h-100 w-100">
          <Spinner />
        </div>
      ) : reviewHistory?.length === 0 ? (
        <div className="hstack justify-content-center h-100 w-100">
          Review Hostory not found!
        </div>
      ) : (
        <div className="overflow-auto p-2">
          <Table
            id="resizable-table"
            striped
            bordered
            hover
            className="m-0 custom-table"
            ref={tableRef}
          >
            <thead>
              <tr>
                <th
                  onMouseDown={(e) => onMouseDown(e, 0)}
                  style={{ width: 50 }}
                  className="text-center"
                >
                  Sr.
                </th>
                <th onMouseDown={(e) => onMouseDown(e, 1)}>Status</th>
                <th onMouseDown={(e) => onMouseDown(e, 2)}>Activity By</th>
                <th onMouseDown={(e) => onMouseDown(e, 3)}>
                  Activity Date | Time
                </th>
              </tr>
            </thead>
            <tbody>
              {reviewHistory?.map((item, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-capitalize">{item.status}</td>
                  <td>{item.fullName}</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm:ss">
                      {item?.createdAt}
                    </Moment>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

export default ReviewHistory;

import React, { useEffect, useState } from "react";
import { Collapse, Form, InputGroup, Spinner } from "react-bootstrap";
import { CiViewTable } from "react-icons/ci";
import SplitPane from "react-split-pane";
import { BiCollapse, BiExpand } from "react-icons/bi";
import STDMMethodTableProperties from "./STDMMethodTableProperties";
import STDMMethodTableData from "./STDMMethodTableData";
import UseToggle from "../../../../Hooks/UseToggle";
import { cdiscServices } from "../../../../APIServices/cdiscServices";
import { GoSearch } from "react-icons/go";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import UpdateMethod from "./UpdateMethod";
import { TbLayoutSidebarRightExpand } from "react-icons/tb";

const STDMMethod = ({ onMouseDown, tableRef }) => {
  const [loader, setLoader] = useState(false);
  const { toggle, setToggle } = UseToggle();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [stdmMethod, setSTDMMethod] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const currentUrl = window.location.href;
  const splitUrl = currentUrl?.split("/");
  const studyId = splitUrl[4];

  const getCDISCMathods = async () => {
    setLoader(true);
    let data = await cdiscServices.getCDISCMathods(studyId);
    setSTDMMethod(data?.data || []);
    setLoader(false);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    getCDISCMathods();
  }, []);

  const HeaderTools = [
    {
      title: "View Table Properties",
      icon: <CiViewTable />,
      onClick: setToggle,
    },
  ];

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div
        className="hstack justify-content-between w-100 border-bottom"
        style={{ padding: "4.6px 9px" }}
      >
        <div className="hstack gap-2">
          {isFormOpen && (
            <>
              <button
                title="Hide Form"
                className="tabButtons border rounded-1 fs-13"
                style={{ padding: "1px 5px" }}
                onClick={() => setIsFormOpen(!isFormOpen)}
              >
                <TbLayoutSidebarRightExpand />
              </button>
              <div className="vr"></div>
            </>
          )}
          {HeaderTools?.map((button, index) => (
            <React.Fragment key={index}>
              <button
                title={button.title}
                className="tabButtons border rounded-1 fs-13"
                style={{ padding: "1px 5px" }}
                onClick={button.onClick}
              >
                {button.icon}
              </button>
              <div className="vr"></div>
            </React.Fragment>
          ))}
          <button
            className="tabButtons rounded-1 border fs-13"
            style={{ padding: "1px 5px" }}
            onClick={() => setOpenFilter(!openFilter)}
          >
            {!openFilter ? <MdFilterAlt /> : <MdFilterAltOff />}
          </button>
          <Collapse in={openFilter} dimension="width">
            <div>
              <InputGroup style={{ width: 200 }}>
                <Form.Control
                  type="search"
                  className="px-2 rounded-0"
                  placeholder="Search..."
                  style={{ fontSize: 10, padding: "3.5px" }}
                />
                <button
                  className="tabButtons border fs-13"
                  style={{
                    padding: "1px 5px",
                    borderRadius: "0 3px 3px 0",
                  }}
                >
                  <GoSearch />
                </button>
              </InputGroup>
            </div>
          </Collapse>
        </div>
        <button
          className="tabButtons border rounded-1 fs-13"
          title={!isFullScreen ? "Full Mode" : "Original Mode"}
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <BiCollapse /> : <BiExpand />}
        </button>
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize={"100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={isFormOpen ? "20%" : "100%"}
          >
            {isFormOpen && (
              <UpdateMethod
                stdmMethod={stdmMethod}
                setSTDMMethod={setSTDMMethod}
                getCDISCMathods={getCDISCMathods}
              />
            )}
            <>
              {loader ? (
                <div className="hstack justify-content-center h-100 w-100">
                  <Spinner />
                </div>
              ) : stdmMethod?.length === 0 ? (
                <div className="hstack justify-content-center h-100 w-100">
                  Data not found!
                </div>
              ) : (
                <STDMMethodTableData
                  stdmMethod={stdmMethod}
                  onMouseDown={onMouseDown}
                  setIsFormOpen={setIsFormOpen}
                  tableRef={tableRef}
                  getCDISCMathods={getCDISCMathods}
                />
              )}
            </>
          </SplitPane>
        </SplitPane>
      </div>
      <STDMMethodTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
    </div>
  );
};

export default STDMMethod;
